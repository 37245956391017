import {
  APIGetNotificationsResponse,
  APIGetUnreadNotificationsResponse,
} from 'models/API/Notifications/Notfications';
import API from '../api';

export const findNotificationsMethod = (params, data) =>
  API.post<APIGetNotificationsResponse>(`/api/v1/search/notifications?${params}`, data);

export const findMyNotificationsMethod = (params, data) =>
  API.post<APIGetNotificationsResponse>(`/api/v1/search/my/notifications?${params}`, data);

export const findUnreadNotifications = () =>
  API.get<APIGetUnreadNotificationsResponse>(`/api/v1/notification/count-unread`);

export const multyMarkAsRead = (ids) => {
  API.post(`/api/v1/notification/set-bulk-read`, {
    notification_ids: ids,
  });
};

// response unused
export const markAsReadMethod = (id) => API.get(`/api/v1/notifications/${id}`);
