export const styleTypeOptions = [
	{ value: 'theme-1', label: 'Style type 1 (default)' },
	{ value: 'theme-2', label: 'Style type 2' },
	{ value: 'theme-3', label: 'Style type 3' },
];

export const colorSetOptions = [
	{ value: 'blackberry', label: 'Blackberry', colorSet: ['#722ed1', '#f9f1ff', '#051367'], },
	{ value: 'mint', label: 'Mint', colorSet: ['#1aceae', '#e8fffb', '#22403b'], },
	{ value: 'ice', label: 'Ice', colorSet: ['#2c63ee', '#e5ecff', '#2a3d6c'], },
	{ value: 'tangerine', label: 'Tangerine', colorSet: ['#4f380b', '#feac0e', '#e79c0c'], },
	{ value: 'raspberry', label: 'Raspberry', colorSet: ['#ca1677', '#ffeef7', '#400a27'], },
	{ value: 'blueberry', label: 'Blueberry', colorSet: ['#1e4e7a', '#e8eff5', '#01213e'], },
];

export const CONFIRM_UPLOAD_IMG_MODAL_KEY = 'CONFIRM_UPLOAD_IMG_MODAL_KEY';
export const REMOVE_IMG_MODAL_KEY = 'REMOVE_IMG_MODAL_KEY';

export const SystemStyleUploadImages = [
	{
		id: 'favicon_crm',
		title: 'Upload logo (Favicon CRM)',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
	},
	{
		id: 'favicon_client',
		title: 'Upload logo (Favicon PC)',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
	},
	{
		id: 'logo_crm',
		title: 'Upload logo (CRM)',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
	},
	{
		id: 'logo_pc',
		title: 'Upload logo (PC)',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB',
	},
	{
		id: 'sign_up',
		title: 'Upload image for Sign Up page',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB.\n Resolution: 1:1',
	},
	{
		id: 'sign_in',
		title: 'Upload image for Sign In page',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB.\n Resolution: 1:1',
	},
	{
		id: 'reset_password',
		title: 'Upload image for Reset Password page',
		description: 'Supported files: .jpeg, .png, .jpg, .gif.\n The maximum size of a single file is 1 MB.\n Resolution: 1:2',
	},
];

export const systemImageTypes = ['jpeg', 'png', 'jpg', 'gif'];