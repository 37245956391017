import { useEffect, useState, useRef, FC } from 'react';
import classNames from 'classnames/bind';
import Button from '../Button/Button';
import NotificationPopup from '../NotificationPopup';
import MTCheckPop from './MTCheckPop';
import { Bell } from 'phosphor-react';
import { notify } from 'utils/notify';
import { useAbsurdLogic } from 'utils/useAbsurdLogic';
import { usePermission } from 'utils/usePermission';
import { findUnreadNotifications } from 'api/notifications';
import { getEnableMT, getEnableWebTrader } from 'constants/selectors';
import { BurgerButton } from './BurgerButton/BurgerButton';
import { useAppDispatch, useAppSelector } from "store";
import { getNotify, setNotificationCount } from 'store/slice/notifySlice';
import styles from './header.module.scss';

const cx = classNames.bind(styles);

const HeaderUserInfo = ({ userInfo }) => {
  return (
    <div className={cx('user_info')}>
      <p className={cx('name')}>
        {userInfo.first_name} {userInfo.last_name || ''}
      </p>
      <p className={cx('text_role')}>{userInfo.role[0]}</p>
    </div>
  );
};

type HeaderProps = {
  isMobile?: boolean;
  onOpenMenu: () => void;
};

const Header: FC<HeaderProps> = ({ isMobile = false, onOpenMenu }) => {
  const [showNotify, setShowNotify] = useState(false);
  const [mtCheckPopIsOpen, setMtCheckPopIsOpen] = useState(false);
  const notifyPopup = useRef(null);

  const dispatch = useAppDispatch();

  const notifyCount = useAppSelector(getNotify);
  const ENABLE_MT = useAppSelector(getEnableMT);
  const ENABLE_WEB_TRADER = useAppSelector(getEnableWebTrader);

  const { permissionGiven: PERMISSION_MT_CHECK } = usePermission('admin.menu-mt-check.view');

  const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));

  //@ts-ignore
  const { MT } = useAbsurdLogic({ ENABLE_WEB_TRADER, ENABLE_MT });

  const handleShowNotify = () => {
    setShowNotify(!showNotify);
  };

  const onClickOutside = (event) => {
    if (notifyPopup.current && !notifyPopup.current.contains(event.target)) {
      setShowNotify(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, []);

  const getUnreadNotify = () => {
    return findUnreadNotifications()
      .then((res) => {
        if (res.status === 200) {
          dispatch(setNotificationCount(res.data.total));
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    getUnreadNotify();
  }, []);

  const checkMTHandler = () => {
    setMtCheckPopIsOpen(!mtCheckPopIsOpen);
  };

  return (
    <header className={cx('header', { mobile: isMobile })}>
      <div className={cx('header__container')}>
        {!isMobile && userInfo ? <HeaderUserInfo userInfo={userInfo} /> : null}

        <div className={cx('header__col')}>{isMobile && <BurgerButton onClick={onOpenMenu} />}</div>

        {PERMISSION_MT_CHECK && MT && (
          <>
            <Button
              onClick={checkMTHandler}
              buttonText="MT Check"
              buttonType="outline"
              size="big"
              type="button"
            />
            <MTCheckPop open={mtCheckPopIsOpen} setOpen={setMtCheckPopIsOpen} />
          </>
        )}
        <div className={cx('notifications')} ref={notifyPopup}>
          <div className={cx('notifications__icon')} onClick={() => handleShowNotify()}>
            <div className={cx(notifyCount ? 'notifications__count' : 'notifications__count empty')}>
              {notifyCount ? notifyCount : null}
            </div>
            <Bell size={25}/>
          </div>
          <div className={cx('notifications__container', showNotify ? 'show' : '')}>
            <NotificationPopup />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
