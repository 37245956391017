import { updateDesksMethod } from 'api/managements/desks';
import {
  addOperatorsMethod,
  getDepartmentRolesMethod,
  getTotalDepartmentsTypesListMethod,
} from 'api/managements/operators';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import FormInputAutoPassword from 'components/ui/FormInputAutoPassword';
import JokerSelect from 'components/ui/JokerSelect';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import {
  PASSWORD_INPUT_VALIDATION,
  PHONE_INPUT_VALIDATION,
  VALID_EMAIL,
} from 'constants/validators';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { notify } from 'utils/notify';
import PaginateSelect from '../../../../components/ui/PaginateSelect/PaginateSelect';
import { fetchBranchListWithParams, fetchUserTypes } from '../../../../utils/managment/fetchData';
import styles from './operators-popup.module.scss';
import { getValue } from '../../../../utils/getValue';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  branchTypeList: ListValue[];
  deskTypeList?: ListValue[];
  userTypesList?: ListValue[];
  langList?: any;
  ruleData?: any;
  actionType: string;
};

const CreateOperatorsPopup = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    branchTypeList,
    deskTypeList,
    userTypesList,
    langList,
    actionType,
    ruleData,
  } = props;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [requiredBranch, setRequiredBranch] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branch_type, setBranchType] = useState(null);
  const [departmentsList, setDepartmentsList] = useState([]);
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        password: undefined,
        phone: undefined,
        sip_account: undefined,
        type_user_id: undefined,
        department_id: undefined,
        role: undefined,
        branch_type: undefined,
        branch_id: undefined,
      };
    }
  };

  const generatePassword = (pass) => {
    setValue('password', pass);
  };

  const setDefaultValuesBranch = () => {
    return {
      first_name: getValues('first_name'),
      last_name: getValues('last_name'),
      email: getValues('email'),
      password: getValues('password'),
      phone: getValues('phone'),
      sip_account: getValues('sip_account'),
      type_user_id: getValues('type_user_id'),
      department_id: getValues('department_id'),
      role: getValues('role'),
      branch_type: getValues('branch_type'),
      branch_id: undefined,
    };
  };

  const setDefaultValuesRole = () => {
    return {
      first_name: getValues('first_name'),
      last_name: getValues('last_name'),
      email: getValues('email'),
      password: getValues('password'),
      phone: getValues('phone'),
      sip_account: getValues('sip_account'),
      type_user_id: getValues('type_user_id'),
      department_id: getValues('department_id'),
      role: '',
      branch_type: getValues('branch_type'),
      branch_id: getValues('branch_id'),
    };
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    setRequiredBranch(false);
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [langList, userTypesList, deskTypeList, ruleData, departmentsList, branchTypeList]);

  const onSubmit = (close, data) => {
    const sendData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      sip_account: data.sip_account,
      department_id: getValue(data['department_id']),
      type_user_id: getValue(data['type_user_id']),
      role: data['role'].label,
      branch_type: getValue(data['branch_type']),
      branch_id: getValue(data['branch_id']),
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addDeskHandler(sendData, close);
    } else {
      editRuleHandler(sendData, close);
    }
  };

  const addDeskHandler = (sendData, close) => {
    setIsLoading(true);

    addOperatorsMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          if (res.data.data.id) {
            navigate(`/operator/${res.data.data.id}`);
          }
          notify({
            type: 'success',
            message: 'Operator created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const updateListBranch = (branch_type) => {
    setBranchType(branch_type);
    reset(setDefaultValuesBranch());
  };
  const getRolesList = (department) => {
    reset(setDefaultValuesRole());
    getDepartmentRolesMethod(department.value).then((res) => {
      if ((res.status = 200)) {
        const roles = res.data.data.roles.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        setRolesList(roles);
      }
    });
  };
  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    updateDesksMethod(ruleData.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Office edited successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const fetchDepartmentsList = () => {
    getTotalDepartmentsTypesListMethod().then((res) => {
      if (res.status === 200) {
        const departments = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        setDepartmentsList(departments);
      }
    });
  };
  const onOpen = () => {
    fetchDepartmentsList();
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New operator' : 'Edit operator'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={6}>
                          <FormInput
                            rules={{
                              required: 'First name field is required',
                              validate: {
                                correctFirstName: (value) =>
                                  value.length < 50 || 'First name too long',
                              },
                            }}
                            label={'First name'}
                            id="first_name"
                            control={control}
                            name="first_name"
                            placeholder={'First name'}
                            errors={errors.first_name}
                          />
                        </Col>
                        <Col md={6}>
                          <FormInput
                            rules={{
                              required: 'Last name field is required',
                              validate: {
                                correctLastName: (value) =>
                                  value.length < 50 || 'Last name too long',
                              },
                            }}
                            label={'Last name'}
                            id="last_name"
                            control={control}
                            name="last_name"
                            placeholder={'Last name'}
                            errors={errors.last_name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormInput
                            type="email"
                            errors={errors.email}
                            label={'Email'}
                            id="email"
                            control={control}
                            name="email"
                            placeholder={'Email'}
                            rules={{
                              required: 'E-mail field is required',
                              validate: VALID_EMAIL,
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <FormInputAutoPassword
                            generatePassword={generatePassword}
                            label={'Password'}
                            id="password"
                            control={control}
                            name="password"
                            placeholder={'Password'}
                            errors={errors.password}
                            rules={{
                              required: 'Password field is required',
                              validate: PASSWORD_INPUT_VALIDATION,
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className={cx('margin-top-16')} md={6}>
                          <FormInput
                            rules={{
                              validate: PHONE_INPUT_VALIDATION,
                            }}
                            maxLength="20"
                            label={'Phone'}
                            id="phone"
                            control={control}
                            name="phone"
                            placeholder={'Phone'}
                            errors={errors.phone}
                          />
                        </Col>
                        <Col className={cx('margin-top-16')} md={6}>
                          <FormInput
                            maxLength="20"
                            label={'SIP account'}
                            id="sip_account"
                            control={control}
                            name="sip_account"
                            placeholder={'SIP account'}
                            errors={errors.sip_account}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <PaginateSelect
                            isRequired={true}
                            isMulti={false}
                            isSearchable={false}
                            label={'User Type'}
                            control={control}
                            id="type_user_id"
                            name="type_user_id"
                            onChange={(page) => fetchUserTypes(page)}
                            errors={errors.type_user_id}
                            rules={{
                              required: 'User Type field is required',
                            }}
                          />
                        </Col>

                        <Col md={6}>
                          <JokerSelect
                            rules={{
                              required: 'Field is required',
                            }}
                            isRequired={true}
                            label={'Department'}
                            onSelectChange={() => {
                              getRolesList(getValues('department_id'));
                            }}
                            control={control}
                            id="department_id"
                            name={'department_id'}
                            placeholder={'--Select--'}
                            rightaligned={true}
                            errors={errors.department_id}
                            options={departmentsList}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <JokerSelect
                            rules={{
                              required: 'Role field is required',
                            }}
                            isDisabled={!getValues('department_id')}
                            isRequired
                            rightaligned
                            label={'Role'}
                            control={control}
                            id="role"
                            name={'role'}
                            placeholder={'--Select--'}
                            errors={errors.role}
                            options={rolesList}
                          />
                        </Col>

                        <Col className={cx('margin-top-16')} md={6}>
                          <JokerSelect
                            rules={{
                              required: 'Branch Type field is required',
                            }}
                            isRequired
                            rightaligned
                            label={'Branch Type'}
                            control={control}
                            id="branch_type"
                            name={'branch_type'}
                            placeholder={'Search'}
                            errors={errors.branch_type}
                            options={branchTypeList}
                            onSelectChange={() => {
                              updateListBranch(getValues('branch_type'));
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className={cx('margin-top-16')} md={6}>
                          <PaginateSelect
                            isSearchable={false}
                            isDisabled={!getValues('branch_type')}
                            isRequired={true}
                            isMulti={false}
                            label={'Branch'}
                            control={control}
                            id="branch_id"
                            name="branch_id"
                            onChange={(page, search, branch_type) => {
                              return fetchBranchListWithParams(branch_type.value, page);
                            }}
                            mainVal={branch_type}
                            errors={errors.role}
                            rules={{
                              required: 'Role field is required',
                            }}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                size="big"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <LoaderButton
                                buttonType="primary"
                                size="big"
                                showSpinner={isLoading}
                                buttonStyle={styles.btnHeight}
                                // disabled={isLoading || !isDirty}
                                buttonText={'Confirm'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateOperatorsPopup;
