import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './filter-select.module.scss';
import JokerMultiSelect from '../JokerMultiSelect/JokerMultiSelect';

const cx = classNames.bind(styles);
const FilterSelect = ({
  onChange,
  dynamicFiltersOpt,
  selectedOptions,
  setSelectedOptions,
  ...props
}) => {
  const { control, formState, setValue } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    setValue('filtersIds', selectedOptions);
  }, [selectedOptions]);
  
  const [open, setOpen] = useState<boolean>(false);
  const filterOpenHandler = () => setOpen(!open);

  const DropdownButton = ({ children, onPress, ...props }) => {
    return (
      <div className={cx('dropdown-button')}>
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
            e.preventDefault();
            onPress();
          }}
        >
          + Filter
        </div>
      </div>
    );
  };

  return (
    <>
      <DropdownButton children={undefined} onPress={filterOpenHandler} />
      <div className={cx('dropdown-wrap')}>
        {open && (
          <JokerMultiSelect
            control={control}
            id="filters"
            name="filtersIds"
            options={dynamicFiltersOpt}
            onChange={(data): void => {
              setSelectedOptions(data);
              onChange(data);
            }}
          />
        )}
      </div>
    </>
  );
};

export default FilterSelect;
