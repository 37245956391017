import * as React from "react"

const UsdSvg = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#F9F1FF"/>
    <path
      d="M18.14 33.784V31.192C14.236 30.84 11.42 28.696 11.42 25.08H15.324C15.452 26.68 16.348 27.864 18.14 28.184V21.144C14.94 20.344 11.42 19.448 11.42 14.936C11.42 11.352 14.172 8.888 18.14 8.536V5.912H20.22V8.504C23.9 8.824 26.396 10.84 26.684 14.456H22.78C22.652 13.176 21.756 11.992 20.22 11.608V18.52C23.42 19.288 26.908 20.152 26.908 24.664C26.908 27.768 24.54 30.776 20.22 31.16V33.784H18.14ZM23.228 24.856C23.228 23.032 21.948 22.232 20.22 21.688V28.184C22.14 27.864 23.228 26.52 23.228 24.856ZM15.132 14.744C15.132 16.6 16.412 17.432 18.14 17.976V11.512C16.38 11.736 15.132 12.792 15.132 14.744Z"
      fill="#722ED1"/>
  </svg>
)

export default UsdSvg;
