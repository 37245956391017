import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './desks-popup.module.scss';
import JokerSelectWithSearch from '../../../../components/ui/JokerSelectWithSearch/JokerSelectWithSearch';
import JokerSelect from '../../../../components/ui/JokerSelect';
import { addDesksMethod, updateDesksMethod } from '../../../../api/managements/desks';
import PaginateSelect from '../../../../components/ui/PaginateSelect/PaginateSelect';
import { getOfficesList } from '../../../../api/office';
import { getLangList } from '../../../../api/lang';
import { SALES_RULES_ACTION_TYPE } from '../../../../constants/sales-rules.const';
import { notify } from '../../../../utils/notify';
import PopupTemplate from '../../../../components/ui/PopupTemplate';
import FormInput from '../../../../components/ui/FormInput';
import Button from '../../../../components/ui/Button';
import LoaderButton from '../../../../components/ui/LoaderButton';
import { ListValue } from 'models/ListValue';

interface ILangList {
  value: number,
  label: string,
}

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  deskTypeList: ListValue[];
  actionType: string;
  langList?: ILangList[];
  officeList?: any;
  ruleData?: any;
  onCloseModal?: () => void;
}

const CreateOrEditDesksPopup = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    deskTypeList,
    officeList,
    actionType,
    ruleData,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [langListArr, setLangListArr] = useState<ILangList[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deskValue, setDeskValue] = useState<object>({});

  const onOpen = () => {
    langListArr.length < 1 &&
    getLangList().then((res) => {
      const mapped = res.data.data.map((item): ILangList => ({
        value: item.id,
        label: item.name,
      }));

      setLangListArr(mapped);
    })
      .catch(console.log);
  };
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        name: undefined,
        office_id: undefined,
        language_id: undefined,
        type: undefined,
      };
    }
    return {
      name: ruleData?.name,
      office_id: { value: ruleData?.office.id, label: ruleData?.office.name },
      language_id: { value: ruleData?.language.id, label: ruleData?.language.name },
      type: { value: ruleData?.type, label: ruleData?.type },
    };
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal): void => {
    reset();
    closeModal();
    if (actionType === SALES_RULES_ACTION_TYPE.EDIT) return;
    setIsOpen(false);
  };

  useEffect(() => {
    reset(setDefaultValues());
    if (actionType === SALES_RULES_ACTION_TYPE.EDIT) {
      setIsOpen(true);
    }
  }, [officeList, deskTypeList, ruleData]);

  const onSubmit = (close, data): void => {
    const sendData = {
      name: data.name,
      office_id: getValue(data['office_id']),
      language_id: getValue(data['language_id']),
      type: data.type.value,
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addDeskHandler(sendData, close);
    } else {
      editRuleHandler(sendData, close);
    }

    if (actionType === SALES_RULES_ACTION_TYPE.EDIT) return;
    setIsOpen(false);
  };

  const addDeskHandler = (sendData, close): void => {
    setIsLoading(true);

    addDesksMethod(sendData)
      .then((res): void => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Desk created successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const editRuleHandler = (sendData, close): void => {
    setIsLoading(true);

    updateDesksMethod(ruleData.id, sendData)
      .then((res): void => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Desk edited successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  const fetchOffices = async (page, query, deskValue) => {
    if (query) {
      if (query.length > 2) {
        return await getOfficesList(`per_page=10&page=${page}`, query.length > 0 && query).then(
          (res) => {
            const opt = res.data.data.map((opt) => ({
              value: opt.id,
              label: opt.name,
            }));
            return { data: opt, total: res.data.meta.last_page };
          },
        )
          .catch(console.log);
      } else return;
    }

    return await getOfficesList(`per_page=10&page=${page}`, null).then((res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
      return { data: opt, total: res.data.meta.last_page };
    })
      .catch(console.log);
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New desk' : 'Edit desk'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={8}>
                          <FormInput
                            label={'Desk name'}
                            id='desk_name'
                            control={control}
                            name='name'
                            placeholder={'Desk name'}
                            errors={errors.name}
                            rules={{
                              required: 'Desk name field is required',
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <JokerSelect
                            isRequired={true}
                            label={'Desk Type'}
                            control={control}
                            id='type'
                            name={'type'}
                            placeholder={'--Select--'}
                            rightaligned={true}
                            options={deskTypeList}
                            onSelectChange={(): void => {
                              setDeskValue(getValues('type'));
                              if (actionType === SALES_RULES_ACTION_TYPE.EDIT) return;
                              setIsOpen(true);
                            }}
                            errors={errors.type}
                            rules={{
                              required: 'Desk Type field is required',
                            }}
                          />
                        </Col>
                      </Row>
                      {isOpen ?
                        <Row>
                          <Col md={6}>
                            <PaginateSelect
                              defaultValue={ruleData?.office}
                              isRequired={true}
                              isMulti={false}
                              label='Office'
                              control={control}
                              id='office'
                              name='office_id'
                              onChange={(page, search) => fetchOffices(page, search, deskValue)}
                              errors={errors.office_id}
                              rules={{
                                required: 'Office field is required',
                              }}
                            />
                          </Col>
                          <Col className={cx('margin-top-16')} md={6}>
                            <JokerSelectWithSearch
                              isRequired={true}
                              label={'Language'}
                              control={control}
                              id='language'
                              name='language_id'
                              placeholder={'--Select--'}
                              rightaligned={true}
                              options={langListArr}
                              errors={errors.language_id}
                              rules={{
                                required: 'Language field is required',
                              }}
                            />
                          </Col>
                        </Row>
                        : null
                      }
                    </Container>
                    <div className={cx('content-controls')}>
                      <Container>
                        <Row>
                          <Col md={12}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText='Cancel'
                                buttonType='outline'
                                size='big'
                                type='button'
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <LoaderButton
                                buttonType='primary'
                                size='big'
                                showSpinner={isLoading}
                                // disabled={isLoading || !isDirty}
                                buttonText={
                                  actionType === SALES_RULES_ACTION_TYPE.CREATE
                                    ? 'Create desk'
                                    : 'Apply change'
                                }
                                type='submit'
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateOrEditDesksPopup;
