import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './teams-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { addTeamsMethod, updateTeamsMethod } from '../../../../api/managements/teams';
import PaginateSelect from '../../../../components/ui/PaginateSelect/PaginateSelect';
import { fetchDesks, fetchOffices } from '../../../../utils/managment/fetchData';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  deskList?: any;
  officeList?: any;
  actionType: string;
  ruleData?: any;
}

type FormValues = {
  name: string;
  office_id: {
      value: string | number;
      label: string | number;
  };
  desk_id: {
      value: string | number;
      label: string | number;
  } | string;
}

const CreateTeamsPopup = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    deskList,
    officeList,
    actionType,
    ruleData,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [office, setOffice] = useState(null);
  const setDefaultValuesDesk = () => {
    return {
      name: getValues('name'),
      office_id: getValues('office_id'),
      desk_id: '',
    };
  };
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        name: undefined,
        office_id: undefined,
        desk_id: undefined,
      };
    }
    return {
      name: ruleData?.name,
      office_id: { value: ruleData?.desk?.office.id, label: ruleData?.desk?.office.name },
      desk_id: { value: ruleData?.desk?.id, label: ruleData?.desk?.name },
    };
  };
  useEffect(() => {
    ruleData &&
      setOffice({ value: ruleData?.desk?.office?.id, label: ruleData?.desk?.office?.name });
  }, [ruleData]);
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const updateDeskWithOffice = (office) => {
    setOffice(office);
    reset(setDefaultValuesDesk());
  };
  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    setOffice(null);
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [officeList, deskList, ruleData]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
      desk_id: getValue(data['desk_id']),
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addDeskHandler(sendData, close);
    } else {
      editRuleHandler(sendData, close);
    }
  };

  const addDeskHandler = (sendData, close) => {
    setIsLoading(true);

    addTeamsMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Team created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
          reset(setDefaultValues());
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    updateTeamsMethod(ruleData.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Team edited successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New team' : 'Edit team'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Tabs className={cx('tabs')}>
                      <TabPanel>
                        <Container>
                          <Row>
                            <Col md={12}>
                              <FormInput
                                label={'Team name'}
                                id="team_name"
                                control={control}
                                name="name"
                                placeholder={'Team name'}
                                errors={errors.name}
                                rules={{
                                  required: 'Team name field is required',
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <PaginateSelect
                                defaultValue={ruleData?.office}
                                isRequired={true}
                                isMulti={false}
                                label="Office"
                                control={control}
                                id="office"
                                name="office_id"
                                onChange={(page, search) => fetchOffices(page, search)}
                                errors={errors.office_id}
                                rules={{
                                  required: 'Office field is required',
                                }}
                                onSelectChange={() => {
                                  updateDeskWithOffice(getValues('office_id'));
                                }}
                              />
                            </Col>
                            <Col md={6}>
                              <PaginateSelect
                                isDisabled={getValues('office_id') ? false : true}
                                isRequired={true}
                                isMulti={false}
                                label="Desk"
                                control={control}
                                id="desk"
                                name="desk_id"
                                onChange={(page, search, office) => {
                                  return fetchDesks(page, search, office.value);
                                }}
                                mainVal={office}
                                errors={errors.desk_id}
                                rules={{
                                  required: 'Desk field is required',
                                }}
                              />
                            </Col>
                          </Row>
                        </Container>
                        <div className={cx('content-controls')}>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className={cx('controls__buttons')}>
                                  <Button
                                    buttonText="Cancel"
                                    buttonType="outline"
                                    size="big"
                                    type="button"
                                    onClick={closeModal.bind(undefined, close)}
                                  />
                                  <LoaderButton
                                    buttonType="primary"
                                    size="big"
                                    showSpinner={isLoading}
                                    // disabled={isLoading || !isDirty}
                                    buttonText={
                                      actionType === SALES_RULES_ACTION_TYPE.CREATE
                                        ? 'Create team'
                                        : 'Apply change'
                                    }
                                    type="submit"
                                    onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </TabPanel>
                      <TabPanel></TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateTeamsPopup;
