import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames/bind';
import moment from 'moment';
import { getPartnersList } from '../../../../api/partners';
import { clientDynamicStatuses } from '../../../../api/clients';
import { getCountryList } from '../../../../api/country';
import { getFilterList } from '../../../../api/filters';
import FilterSelect from '../../../../components/ui/FilterSelect';
import FilterSetSelect from '../../../../components/ui/FilterSetSelect';
import { fullDateTimeFormat } from '../../../../components/ui/ModernDateRangeInput';
import Search from '../../../../components/ui/Search';
import {
  ACQUISITION_SELECT_OPTIONS,
  ACTIVITY_SELECT_OPTIONS,
  FIRST_TIME_DEPOS_SELECT_OPTIONS,
  NOTES_SELECT_OPTIONS,
  PROFILE_STATUS_SELECT_OPTIONS,
} from '../../../../constants/clients/filters.const';
import { isEmpty } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { getSystemData } from '../../../../api/system';
import ButtonReset from '../../../../components/ui/Button/ButtonReset';
import {
  fetchDesksNoPagination,
  fetchLanguagesPaginate,
  fetchOperatorsNoPagination,
  fetchTeamsForDeskNoPagination,
  fetchTeamsNoPagination,
  fetchOperatorsForBulkActionsNoPagination,
  fetchOperatorsForBulkActionsByDeskNoPagination,
} from '../../../../utils/managment/fetchData';
import styles from './client-filter.module.scss';
import './reload-button.scss';
import { isNotEmptyFilterOption } from '../../../../helpers/client.clientFilter.isEmptyOptions.helper';
import { getReferrals, getSource } from '../../../../api/Source';
import SearchSectionAllFilters from './ClientFilterSections/SearchSectionAllFilters';
import SearchSection from './ClientFilterSections/SearchSection';
import { MOBILE_SIZE_WIDTH } from '../../../../constants/mobile';
import { CountryOpt, CustomFilter, DynamicFilterRequiredType } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  changeLimits?: any;
  reloadFilters: boolean;
  useFormReturn: UseFormReturn<any>;
  getDynamicStatusKey: (data?) => any;
  getDefaultValue: () => any;
  dynamicFilterList: DynamicFilterRequiredType[];
  setDynamicFilterList: (list: DynamicFilterRequiredType[]) => void;
  submit: (data: any) => void;
  expanded: boolean;
};

const ClientFilter = (props: Props) => {
  const {
    changeLimits,
    reloadFilters,
    useFormReturn,
    getDefaultValue,
    getDynamicStatusKey,
    dynamicFilterList,
    setDynamicFilterList,
    submit,
    expanded,
  } = props;

  const [filterSet, setFilterSet] = useState([]);
  const [optionsFetched, setOptionsFetched] = useState(false);
  const [selectedDynamicFilter, setSelectedDynamicFilter] = useState([]);
  // CountryOpt[]
  const [countyOpt, setCountryOpt] = useState<CountryOpt[]>([]);
  const [languageOpt, setLanguageOpt] = useState<ListValue[]>([]);
  const [desks, setDesks] = useState([]);
  const [teams, setTeams] = useState([]);
  const [operator, setOperator] = useState([]);
  const [sources, setSources] = useState<ListValue[]>([]);
  const [referrals, setReferrals] = useState<ListValue[]>([]);
  const [oldFilters, setOldFilters] = useState(false);
  const [assignStatusList, setAssignStatusList] = useState<ListValue[]>([]);
  const [customFilterList, setCustomFilterList] = useState<CustomFilter[]>([]);
  const [affiliate, setAffiliate] = useState<ListValue[]>([]);
  const [selectedDesks, setSelectedDesks] = useState<ListValue[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<ListValue[]>([]);

  useEffect(() => {
    setCustomFilterList([
      {
        label: 'Languages',
        options: languageOpt,
        value: 'languageIds',
        type: 'select',
      },
      {
        label: 'Search by',
        options: [],
        value: 'query',
        type: 'input',
      },
      {
        label: 'Notes',
        options: NOTES_SELECT_OPTIONS,
        value: 'notes',
        type: 'select_one',
      },
      {
        label: 'Referral',
        options: referrals,
        value: 'referrals',
        type: 'paginate_select',
        getOptions: fetchReferralsList,
      },
      {
        label: 'Source',
        options: sources,
        value: 'sources',
        type: 'paginate_select',
        getOptions: fetchSourceList,
      },
      {
        label: 'Activity',
        options: ACTIVITY_SELECT_OPTIONS,
        value: 'activity',
        type: 'select_one',
      },
      {
        label: 'Country',
        options: countyOpt,
        value: 'countryIds',
        type: 'select',
      },
      {
        label: 'Desks',
        value: 'deskIds',
        type: 'descsSelect',
        options: desks,
      },
      {
        label: 'Teams',
        value: 'teamIds',
        type: 'teamsSelect',
        options: teams,
      },
      {
        label: 'Operators',
        value: 'operatorIds',
        type: 'operatorsSelect',
        options: operators,
      },
      {
        label: 'Affiliates',
        options: affiliate,
        value: 'affiliateIds',
        type: 'select',
      },
      {
        label: 'Assign status',
        value: 'assign_status',
        type: 'select_one',
        options: assignStatusList,
      },
      {
        label: 'First time deposit',
        options: FIRST_TIME_DEPOS_SELECT_OPTIONS,
        value: 'first_time_deposit',
        type: 'select_one',
      },
      {
        label: 'Profile status',
        options: PROFILE_STATUS_SELECT_OPTIONS,
        value: 'profile_status',
        type: 'select_one',
      },
      {
        label: 'Acquisition status',
        options: ACQUISITION_SELECT_OPTIONS,
        value: 'desk_type',
        type: 'select_one',
      },
      {
        label: 'Registration date range',
        value: 'created_at',
        type: 'date',
      },
      {
        label: 'FTD date range',
        value: 'ftd_range',
        type: 'date',
      },
      {
        label: 'First note date',
        value: 'note_first',
        type: 'date',
      },
      {
        label: 'Last trade date',
        value: 'last_trade',
        type: 'date',
      },
      {
        label: 'Last note date',
        value: 'note_last',
        type: 'date',
      },
      {
        label: 'Last login date',
        value: 'last_login',
        type: 'date',
      },
      {
        label: 'Last modification',
        value: 'updated_at',
        type: 'date',
      },
      // {
      //   label: 'Search limit',
      //   value: 'limit',
      //   type: 'date',
      // },
      {
        label: 'Balance',
        value: 'balance',
        type: 'range',
      },
      {
        label: 'Number of deposits',
        type: 'range',
        value: 'number_deposits',
      },
      ...dynamicFilterList,
    ]);
  }, [
    dynamicFilterList,
    countyOpt,
    languageOpt,
    affiliate,
    desks,
    operator,
    teams,
    referrals,
    sources,
    assignStatusList,
  ]);
  const componentMounted = useRef(true);
  const width = useWindowWidth();

  const isMobile = () => width < MOBILE_SIZE_WIDTH;

  const {
    handleSubmit,
    reset,
    resetField,
    getValues,
    setValue,
    control,
    formState: { dirtyFields },
  } = useFormReturn;

  const { fields, append, prepend, remove, swap, move, replace, insert } = useFieldArray({
    control,
    name: 'dynamic_statuses',
  });
  const fetchAssignStatuses = () => {
    getSystemData()
      .then((res) => {
        const opt = res.data.user_assign_statuses.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setAssignStatusList(opt);
      })
      .catch(console.log);
  };

  const resetForm = () => {
    fetchClientDynamicStatuses();
    reset(getDefaultValue());
    // onSubmit(null);
    submit(getValues());
  };

  const handleResetFields = (fields: string[]) => {
    fields.forEach((field) => {
      resetField(field);
    });
    submit(getValues());
  };

  const resetBalance = () => {
    handleResetFields(['balance', 'number_deposits']);
  };

  const resetActivity = () => {
    handleResetFields(['activity', 'languageIds', 'countryIds']);
  };

  const resetTeam = () => {
    handleResetFields([
      'teamIds',
      'operatorIds',
      'affiliateIds',
      'first_time_deposit',
      'deskIds',
      'notes',
      'referrals',
      'sources',
    ]);
  };

  const resetStatuses = () => {
    handleResetFields([
      'sales',
      'retention',
      'kyc',
      'profile_status',
      'desk_type',
      'assign_status',
    ]);
  };

  const resetDates = () => {
    handleResetFields([
      'updated_at',
      'last_login',
      'note_last',
      'last_trade',
      'note_first',
      'ftd_range',
      'created_at',
    ]);
    // resetField('limit');
  };

  useEffect(() => {
    if (expanded && !optionsFetched) {
      fetchCountryList();
      fetchFilterSet();
      fetchAssignStatuses();
      fetchClientDynamicStatuses();
      fetchSourceList();
      fetchReferralsList();
      fetchLanguages();
      fetchAffiliateList();
      fetchDesksList();
      fetchTeamsList([]);

      setOptionsFetched(true);
    }
  }, [expanded]);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const newTeams = getValues().teamIds.filter((item) =>
      teams.find((t) => t.value === item.value),
    );
    setValue('teamIds', [...newTeams]);
  }, [teams]);

  const fetchLanguages = () => {
    fetchLanguagesPaginate().then((res) => {
      setLanguageOpt(res.data);
    });
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatuses()
      .then((res) => {
        if (componentMounted.current) {
          const mapped = [];
          res.data.data.forEach((opt) => {
            mapped.push({
              value: opt.key,
              label: opt.translate,
              type: opt.type,
              options: opt.activeStatuses.map((item) => ({
                value: item.key,
                label: item.translate,
              })),
            });
          });
          setDynamicFilterList(mapped);
        }
      })
      .catch(console.log);
  };

  const fetchReferralsList = useCallback(
    async (page = 1, query = '') => {
      return await getReferrals({ page, query })
        .then((res) => {
          if (componentMounted.current) {
            const { data, meta } = res.data.referrals;

            const opt = data.map((item: string) => ({ value: item, label: item }));
            setReferrals((prev) => prev.concat(opt));

            return { data: opt, total: Math.ceil(meta.total / meta.per_page) };
          }
        })
        .catch(console.log);
    },
    [componentMounted.current],
  );

  const fetchSourceList = useCallback(
    async (page = 1, query = '') => {
      return await getSource({ page, query })
        .then((res) => {
          if (componentMounted.current) {
            const { data, meta } = res.data.sources;

            const opt = data.map((item: string) => ({ value: item, label: item }));
            setSources((prev) => prev.concat(opt));

            return { data: opt, total: Math.ceil(meta.total / meta.per_page) };
          }
        })
        .catch(console.log);
    },
    [componentMounted.current],
  );

  const fetchDesksList = async () => {
    let options = [];
    const response = await fetchDesksNoPagination(1);
    if (response.fullData.status === 200) {
      options = response.data;
    }
    setDesks(options);
    return { data: options };
  };

  const fetchTeamsList = async (desks: ListValue[]) => {
    let options: ListValue[] = [];
    if (desks.length > 0) {
      const response = await fetchTeamsForDeskNoPagination(
        1,
        undefined,
        desks.map((desk) => desk.value),
      );
      options = response.data;
      const receivedIDs = options.map((opt) => opt.value);
      if (selectedTeams) {
        const filteredTeams = selectedTeams.filter(
          (team) => receivedIDs.indexOf(team.value) !== -1,
        );
        setSelectedTeams(filteredTeams);
        setValue('teamIds', filteredTeams);
      }
    } else {
      const response = await fetchTeamsNoPagination(1);
      options = response.data;
    }

    setTeams(options);
    return { data: options };
  };

  const [operators, setOperators] = useState([]);

  const fetchOperatorsFunc = async (page, search, desks: ListValue[]) => {
    let filteredSelectedOps: ListValue[] = getValues().operatorIds;

    if (selectedTeams.length > 0) {
      const teamsIDs = selectedTeams.map((team) => team.value);

      const ops: { data: ListValue[] } = await fetchOperatorsForBulkActionsNoPagination(
        undefined,
        teamsIDs,
      );
      setOperators(ops.data || []);
      const receivedIDs = ops.data.map((op) => op.value);
      filteredSelectedOps = filteredSelectedOps.filter(
        (op) => receivedIDs.indexOf(op.value) !== -1,
      );
    } else if (desks.length > 0) {
      const ops = await fetchOperatorsForBulkActionsByDeskNoPagination(
        undefined,
        desks.map((d) => d.value),
      );
      setOperators(ops.data || []);
      const receivedIDs = ops.data.map((op) => op.value);
      filteredSelectedOps = filteredSelectedOps.filter(
        (op) => receivedIDs.indexOf(op.value) !== -1,
      );
    } else {
      const ops = await fetchOperatorsNoPagination(page, search);
      setOperators(ops.data);
    }

    setValue('operatorIds', filteredSelectedOps);
  };

  useEffect(() => {
    fetchOperatorsFunc(1, undefined, selectedDesks);
  }, [selectedTeams]);

  const handleSetSelectedDesks = (value: ListValue[]) => {
    setSelectedDesks(value);
    fetchOperatorsFunc(1, undefined, value);
    fetchTeamsList(value);
  };

  const fetchAffiliateList = async () => {
    let options = [];

    const response = await getPartnersList('per_page=500');

    if (response.status === 200) {
      options = response.data.data.map((opt) => ({
        value: opt.id,
        label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
      }));
      options.unshift({ value: 0, label: 'No affiliate' });
      setAffiliate(options);
    }

    return { data: options };
  };

  const fetchCountryList = () => {
    getCountryList()
      .then((res) => {
        if (componentMounted.current) {
          const opt = res.data.data.map((opt) => ({
            value: opt.id,
            label: opt.name,
            iso_3166_2: opt.iso_3166_2,
          }));
          setCountryOpt([{ value: '0', label: 'Other' }, ...opt]);
        }
      })
      .catch(console.log);
  };

  const toggleHandler = () => {
    setSelectedDynamicFilter([]);
    setOldFilters(!oldFilters);
    reset(getDefaultValue());
  };

  const fetchFilterSet = () => {
    getFilterList('type=client&per_page=3000')
      .then((res) => {
        if (componentMounted.current) {
          setFilterSet(res?.data?.data);
        }
      })
      .catch(console.log);
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;
  const getDirtyFieldsLengthBalance = (arr) =>
    arr?.some((item) => Object.keys(dirtyFields).includes(item));

  const removeEmptyFilters = (data) => {
    const filterKeys = Object.keys(getValues());
    const currentData: string[] = data.map((item) => item.value);

    for (let i = 0; i < filterKeys.length; i++) {
      if (!currentData.includes(filterKeys[i])) {
        // @ts-ignore
        resetField(filterKeys[i]);
      }
    }
  };

  const handleChange = (data): void => {
    const vals = getDefaultValue();

    setSelectedDynamicFilter(data.filter.dynamic_statuses);

    if (!data) {
      return;
    }

    if (oldFilters) {
      setOldFilters(false);
    }

    if (data.filter) {
      for (const key of Object.keys(data.filter)) {
        if (key !== 'oldFilters') {
          vals[key] = data.filter[key];
        }
        if (key === 'dynamic_statuses') {
          setSelectedDynamicFilter(data.filter[key]);
        }
        if (key === 'oldFilters') {
          setOldFilters(data.filter['oldFilters']);
        }
      }
    }
    vals['custom_filters'] = getValues('custom_filters');

    /**
     * Delays the execution of the reset function to ensure other operations complete first.
     * Needed to prevent the case when the reset function is called but the dirty state is not updated.
     */
    setTimeout(() => {
      /**
       * Resets the form with the provided values while keeping dirty state and dirty values.
       * @param {object} vals - The values to reset the form with.
       * @param {object} options - Options for resetting the form.
       * @param {boolean} options.keepDirty - Whether to keep the form dirty state.
       * @param {boolean} options.keepDirtyValues - Whether to keep the dirty values.
       */
      reset(vals, { keepDirty: true, keepDirtyValues: true });
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={cx('filters')}>
        <Row>
          <Col md={12} className={cx('filters-config')}>
            <div className={cx('filters-toggle')}>
              <label className="search-clients__toggle">
                <Toggle checked={oldFilters} icons={false} onChange={toggleHandler} />
                <span>Show all filters</span>
              </label>
            </div>

            {!oldFilters && (
              <FilterSelect
                selectedOptions={selectedDynamicFilter}
                dynamicFiltersOpt={customFilterList}
                setSelectedOptions={setSelectedDynamicFilter}
                onChange={(data) => {
                  removeEmptyFilters(data);
                  replace(data.map((item) => (!!item?.data ? item.data : item)));
                }}
              />
            )}

            <div className={cx('filters-sets')}>
              <FilterSetSelect
                label="Customize filters"
                control={control}
                id="custom_filters"
                name="custom_filters"
                placeholder={'--Select--'}
                options={filterSet}
                updateList={fetchFilterSet}
                onSelectChange={handleChange}
              />
            </div>
            <ButtonReset
              buttonText="Reset all filters"
              type="reset"
              onClick={() => {
                resetForm();
                setSelectedDynamicFilter([]);
              }}
              disabled={!getDirtyFieldsLength()}
            />
          </Col>
        </Row>

        {oldFilters ? (
          <SearchSectionAllFilters
            resetTeam={resetTeam}
            getDirtyFieldsLength={getDirtyFieldsLength}
            resetDates={resetDates}
            resetStatuses={resetStatuses}
            assignStatusList={assignStatusList}
            dynamicFilterList={dynamicFilterList}
            setSelectedDynamicFilter={setSelectedDynamicFilter}
            // referrals={referrals}
            // sources={sources}
            affiliate={affiliate}
            desks={desks}
            teams={teams}
            operators={operators}
            selectedDesks={selectedDesks}
            setSelectedDesks={handleSetSelectedDesks}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            resetActivity={resetActivity}
            resetBalance={resetBalance}
            resetForm={resetForm}
            submit={submit}
            control={control}
            handleSubmit={handleSubmit}
            countryOpt={countyOpt}
            getDirtyFieldsLengthBalance={getDirtyFieldsLengthBalance}
            languageOpt={languageOpt}
            fetchReferralsList={fetchReferralsList}
            fetchSourceList={fetchSourceList}
          />
        ) : (
          <SearchSection
            selectedDesks={selectedDesks}
            setSelectedDesks={handleSetSelectedDesks}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            desks={desks}
            teams={teams}
            operators={operators}
            reloadFilters={reloadFilters}
            isNotEmptyFilterOption={isNotEmptyFilterOption}
            isMobile={isMobile}
            oldFilters={oldFilters}
            isEmpty={isEmpty}
            assignStatusList={assignStatusList}
            fields={fields}
            filterSet={filterSet}
            customFilterList={customFilterList}
            fetchFilterSet={fetchFilterSet}
            fetchClientDynamicStatuses={fetchClientDynamicStatuses}
            resetForm={resetForm}
            clearValues={() => {
              const state = { ...getValues() };
              const defaults = getDefaultValue();
              const statuses = [...state.dynamic_statuses];

              const resetParameters = {
                ...defaults,
                dynamic_statuses: fields,
              };

              // Clean state.custom_filters
              if (!isEmpty(state.custom_filters)) {
                const custom = { ...state.custom_filters };
                const resetCustomFields: any = {};

                Object.entries(custom.filter).forEach((entry) => {
                  resetCustomFields[entry[0]] = defaults[entry[0]];
                });

                const newCustomDynamics: any[] = [];

                custom.filter.dynamic_statuses.forEach((status, i) => {
                  const copy = { ...status };

                  delete copy[copy.value];

                  newCustomDynamics.push(copy);
                });

                resetParameters.custom_filters = {
                  ...state.custom_filters,
                  filter: {
                    ...resetCustomFields,
                    dynamic_statuses: newCustomDynamics,
                  },
                };

                replace(newCustomDynamics);
              }

              // Clean state.dynamic_statuses
              statuses.forEach((status, i) => {
                delete statuses[i][status.value];
              });

              resetParameters.dynamic_statuses = statuses;

              reset(resetParameters);
            }}
            getValues={getValues}
            submit={submit}
            control={control}
            handleSubmit={handleSubmit}
            setSelectedDynamicFilter={setSelectedDynamicFilter}
          />
        )}
      </form>
    </>
  );
};

export default memo(ClientFilter);
