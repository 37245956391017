import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './offices-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { addRule, updateRule } from 'api/rules';
import { addOfficeMethod, updateOfficeMethod } from '../../../../api/managements/offices';
import JokerSelectWithSearch from '../../../../components/ui/JokerSelectWithSearch/JokerSelectWithSearch';
import { CountryListValue } from 'models/Countries';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  countryList: CountryListValue[];
  actionType: string;
  ruleData?: HierarchyOffice;
}

const CreateOfficesPopup = (props: Props) => {
  const { triggerBtn, updateComponent, countryList, actionType, ruleData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const setDefaultValues = () => {
    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      return {
        name: undefined,
        country_ids: undefined,
        brand_id: 1,
      };
    }
    return {
      name: ruleData?.name,
      country_ids: { value: ruleData?.country?.id, label: ruleData?.country?.name },
      brand_id: 1,
    };
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [countryList, ruleData]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
      country_id: getValue(data['country_ids']),
      brand_id: data.brand_id,
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addOfficeHandler(sendData, close);
    } else {
      editRuleHandler(sendData, close);
    }
  };

  const addOfficeHandler = (sendData, close) => {
    setIsLoading(true);

    addOfficeMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Office created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    updateOfficeMethod(ruleData?.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Office edited successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New office' : 'Edit office'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Tabs className={cx('tabs')}>
                      <TabPanel>
                        <Container>
                          <Row>
                            <Col md={12}>
                              <FormInput
                                rules={{
                                  required: 'Office name field is required',
                                }}
                                label={'Office name'}
                                id="office_name"
                                control={control}
                                name="name"
                                placeholder={'Office name'}
                                errors={errors.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <JokerSelectWithSearch
                                isRequired={true}
                                label="Country"
                                control={control}
                                id="country"
                                name="country_ids"
                                placeholder={'Search'}
                                rightaligned={true}
                                options={countryList}
                                errors={errors.country_ids}
                                rules={{
                                  required: 'Country field is required',
                                }}
                              />
                            </Col>
                          </Row>
                        </Container>
                        <div className={cx('content-controls')}>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className={cx('controls__buttons')}>
                                  <Button
                                    buttonText="Cancel"
                                    buttonType="outline"
                                    size="big"
                                    type="button"
                                    onClick={closeModal.bind(undefined, close)}
                                  />
                                  <LoaderButton
                                    buttonType="primary"
                                    size="big"
                                    showSpinner={isLoading}
                                    // disabled={isLoading || !isDirty}
                                    buttonText={
                                      actionType === SALES_RULES_ACTION_TYPE.CREATE
                                        ? 'Create office'
                                        : 'Apply change'
                                    }
                                    type="submit"
                                    onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </TabPanel>
                      <TabPanel></TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateOfficesPopup;
