import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import DatePickerRange from 'components/ui/DatePickerRange';
import { NotificationsFilter as FilterType } from 'models/Notifications/NotificationsFilter';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: FilterType) => void;
  reloadFilters: boolean;
  desksOptions: ListValue[];
  teamsOptions: ListValue[];
  agentsOptions: ListValue[];
  notificationsTypesOptions: ListValue[];
  notificationsDetailsTypesOptions: ListValue[];
  queryMessage: string,
}

const NotificationsFilter = (props: Props) => {
  const {
    onSubmit,
    reloadFilters,
    desksOptions,
    teamsOptions,
    agentsOptions,
    notificationsTypesOptions,
    notificationsDetailsTypesOptions,
    queryMessage,
    setQueryMessage
  } = props;

  const componentMounted = useRef(true);
  const notificationsSearchOptions = useSearchCategoriesOptions('notification');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      created_at: '',
      desk_ids: [],
      team_ids: [],
      agents: [],
      type: [],
      details: [],
    };
  };
  const { handleSubmit, reset, getValues, control, formState, watch } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, notificationsSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        created_at: data['created_at'],
        desk_ids: getValue(data['desk_ids']),
        team_ids: getValue(data['team_ids']),
        agent: getValue(data['agents']),
        type: getValue(data['type']),
        details: getValue(data['details']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={notificationsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Desks"
              control={control}
              id="desk_ids"
              name="desk_ids"
              options={desksOptions}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Teams"
              control={control}
              id="team_ids"
              name="team_ids"
              options={teamsOptions}
              disabled={true}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Agents"
              control={control}
              id="agents"
              name="agents"
              options={agentsOptions}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Notification type"
              control={control}
              id="type"
              name="type"
              options={notificationsTypesOptions}
              disabled={true}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Notification types details"
              control={control}
              id="details"
              name="details"
              options={notificationsDetailsTypesOptions}
              disabled={true}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label={'Notification creation date range'}
              placeholder="From - To"
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <div className={cx('filters__btns')}>
              <Button
                className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
                icon={<ArrowsClockwise color="var(--white)" size={18} />}
                onClick={(e) => {
                  e.preventDefault();
                  submit(getValues());
                }}
              />
              <Button
                buttonText="Reset"
                type="reset"
                onClick={() => resetForm()}
                disabled={!getDirtyFieldsLength()}
              />
              <Button
                buttonText="Apply"
                type="submit"
                onClick={handleSubmit(submit)}
                disabled={!getDirtyFieldsLength()}
              />
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default NotificationsFilter;
