export const BROWN = '#462c1c';
export const PURPLE = '#722ed1';
export const GREEN = '#795c11';
export const ORANGE = '#8b471a';
export const BLUE = '#04145c';
export const LIGHT_BLUE = '#8bcbf5';

export const DEFAULT_COLORS = [BROWN, PURPLE, GREEN, ORANGE, BLUE, LIGHT_BLUE]
export const DEFAULT_COLORS_FOR_DYNAMIC_STATUSES = [PURPLE, '#A18594', BROWN, GREEN, '#BEBD7F', '#E4717A', '#FFBCAD', '#FFD1DC', '#3EB489', '#7FB5B5', '#A8E4A0', '#FF8C69', '#EFA94A', '#FFDB8B', '#52b0ff', LIGHT_BLUE]
export const COLORS = {
  MAIN_COLOR: process.env.REACT_APP_MAIN_COLOR||'#722ed1',
  DISABLED_TEXT_COLOR: process.env.REACT_APP_DISABLED_TEXT_COLOR||'#8c8c8c',
  HEADER_BACKGROUND_COLOR: process.env.REACT_APP_HEADER_BACKGROUND_COLOR||'#ffffff',
  MAIN_COLOR_HOVER: process.env.REACT_APP_MAIN_COLOR_HOVER||'#540cb9',
  MAIN_LIGHT_COLOR: process.env.REACT_APP_MAIN_LIGHT_COLOR||'#f9f1ff',
  PRIMARY_TEXT_COLOR: process.env.REACT_APP_PRIMARY_TEXT_COLOR||'#262626',
  SECONDARY_TEXT_COLOR: process.env.REACT_APP_SECONDARY_TEXT_COLOR||'#595959',
  SIDEBAR_BACKGROUND_COLOR: process.env.REACT_APP_SIDEBAR_BACKGROUND_COLOR||'#fff',
  DEFAULT_COLOR: PURPLE,
  BODY_BACKGROUND_COLOR: process.env.REACT_APP_BODY_BACKGROUND_COLOR||'#fff',
  BORDER_BACKGROUND_COLOR: process.env.REACT_APP_BORDER_BACKGROUND_COLOR||'#722ed1',
  CURRENCY_COLOR: process.env.REACT_APP_CURRENCY_COLOR //||'#fff',
};
