import { ColorSetOption } from "../ColorSetOption/ColorSetOption";
import { ColorSetSingleValue } from "../ColorSetSingleValue/ColorSetSingleValue";
import SelectComponent from "../../../../../components/ui/Select";
import LoaderButton from "../../../../../components/ui/LoaderButton";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { getStyles, selectClientDesign } from "../../../../../store/slice/visualSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { colorSetOptions, styleTypeOptions } from "../../../../../constants/systemStyles";
import { setStyleListMethod } from "../../../../../api/settingsLK";
import { notify } from "../../../../../utils/notify";
import { SystemStylePreview } from "../SystemStylePreview/SystemStylePreview";

export const ThemesTab = () => {
	const dispatch = useAppDispatch();
	const [isSubmitting, setSubmitting] = useState(false);
	const { theme: selectedTheme, colorset: selectedColor, logo_pc } = useAppSelector(selectClientDesign);

	const { handleSubmit, control, reset } = useForm();
	const { theme, colorset } = useWatch({control});

	useEffect(() => {
		if (selectedTheme && selectedColor) {
			reset({
				theme: styleTypeOptions.find(option => option.value === selectedTheme),
				colorset: colorSetOptions.find(option => option.value === selectedColor)
			})
		}
	}, [selectedTheme, selectedColor]);

	const onSubmitStyles = async (data) => {
		try {
			setSubmitting(true);
			await setStyleListMethod({
				theme: data.theme.value,
				colorset: data.colorset.value
			});
			dispatch(getStyles());
			notify({
				type: 'success',
				message: 'The styles are updated',
				timeOut: 2000
			})
		} catch (e) {
			notify({
				type: 'error',
				message: 'Failed to update the styles',
				timeOut: 2000
			})
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmitStyles)}>
				<Row>
					<Col md={4}>
						<SelectComponent
							name="theme"
							label="Style type"
							control={control}
							options={styleTypeOptions}
						/>
					</Col>
					<Col md={4}>
						<SelectComponent
							name="colorset"
							label="Color set"
							control={control}
							options={colorSetOptions}
							components={{ Option: ColorSetOption, SingleValue: ColorSetSingleValue }}
						/>
					</Col>
					<Col md={4} className="d-flex items-center">
						<div className="content-controls">
							<div className="controls__buttons">
								<LoaderButton
									type="submit"
									buttonText='Save changes'
									buttonType={isSubmitting ? 'disabled' : 'outline'}
									showSpinner={isSubmitting}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</form>
			<div className="client-preview">
				<span className="title">Preview</span>
				<div className="client-preview__content">
					<SystemStylePreview className={`light ${colorset?.value ?? colorset} ${theme?.value ?? theme}`} logo={logo_pc}/>
					<SystemStylePreview className={`dark ${colorset?.value ?? colorset} ${theme?.value ?? theme}`} logo={logo_pc}/>
				</div>
			</div>
		</>
	)
}