import classNames from 'classnames/bind';
import { Trash } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Popup from 'reactjs-popup';
import PaginateSelect from '../../../../components/ui/PaginateSelect';
import { fetchOperatorsSalesRules, fetchPartners } from '../../../../utils/managment/fetchData';
import styles from './sales-rules-popup.mudule.scss';
import { IPriorityOpt } from '../../../../types/salesRules';
import { addRule } from '../../../../api/rules';
import { notify } from '../../../../utils/notify';
import PopupTemplate from '../../../../components/ui/PopupTemplate';
import FormInput from '../../../../components/ui/FormInput';
import JokerSelect from '../../../../components/ui/JokerSelect';
import JokerMultiSelect from '../../../../components/ui/JokerMultiSelect';
import Button from '../../../../components/ui/Button';
import LoaderButton from '../../../../components/ui/LoaderButton';
import { MuiChipsInput, MuiChipsInputChip } from "mui-chips-input";
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

const priorityOpt: IPriorityOpt[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

type Props = {
  triggerBtn: React.ReactElement,
  updateComponent: () => void,
  countryList: ListValue[],
  langList: ListValue[],
  operatorsList: any,
  partnersList: any,
  actionType: string,
  ruleData?: any,
  sourceArray?: any,
}

const CreateSalesRulesPopup = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    countryList,
    langList,
    operatorsList,
    partnersList,
    actionType,
    ruleData,
    sourceArray,
  } = props

  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState<MuiChipsInputChip[]>(sourceArray || []);
  const [sourceIsChange, seSourceIsChange] = useState(false);
  const [summ, setSumm] = useState(0);
  const ref = useRef(null);
  const contentToScroll = useRef(null);
  const dynamicHeightContent = useRef(null);
  const countrySelectRef = useRef(null);
  const langSelectRef = useRef(null);
  const partnerSelectRef = useRef(null);

  const setDefaultValues = () => {
    return {
      name: null,
      priority: null,
      country_ids: null,
      language_ids: null,
      partner_ids: null,
      operators: [
        {
          ration: null,
          admin_user_id: null,
        },
      ],
    };
  };

  const { handleSubmit, getValues, control, reset, watch, formState, setError, clearErrors } = useForm({
    // reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const operatorArr = watch('operators').map((el) => +el.ration);

  const { isDirty } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'operators',
  });
  const [lengthFields, setLengthFields] = useState(fields.length);
  const closeModal = (close) => {
    setSource(sourceArray || []);
    reset(setDefaultValues());
    close?.();
  };

  useEffect(() => {
    const defaultValue = sourceArray ? sourceArray.join(', ') : '';
    const target = source ? source.join(', ') : '';

    if (defaultValue !== target) {
      seSourceIsChange(true);
    } else {
      seSourceIsChange(false);
    }
  }, [source]);

  useEffect(() => {
    reset(setDefaultValues());
  }, [countryList, langList, operatorsList, partnersList, ruleData]);

  const onSubmit = (close, data) => {
    if (summ > 10) {
      notify({
        type: 'error',
        message: 'The ratio must not exceed 10.',
        timeOut: 3000,
      });
      return;
    }

    const operators = [];

    data.operators?.forEach((item, index) => {
      if (!item['admin_user_id']?.value) {
        return;
      }

      operators.push({
        ...item,
        admin_user_id: item['admin_user_id'].value,
      });
    });

    const sourceJson = {};

    source.forEach((item, index) => {
      sourceJson[index] = item;
    });

    const sendData = {
      name: data.name,
      priority: getValue(data['priority']),
      country_ids: getValue(data['country_ids']),
      language_ids: getValue(data['language_ids']),
      partner_ids: getValue(data['partner_ids']),
      source: JSON.stringify(sourceJson),
      operators,
    };
    addRuleHandler(sendData, close);
  };

  const addRuleHandler = (sendData, close) => {
    setIsLoading(true);

    addRule(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Rule created successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLastIndex = () => {
    return fields.length - 1;
  };

  const checkIsLastSelectInArrayIsEmpty = (currentIndex) => {
    return (
      !fields[getLastIndex()].admin_user_id &&
      !fields[getLastIndex()].ration &&
      getLastIndex() === currentIndex
    );
  };
  useEffect(() => {
    setLengthFields(fields.length);
  }, [fields]);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const matchSumm = () => {
    let initialValue = 0;

    const sum = operatorArr.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue,
    );
    setSumm(sum);
  };

  useEffect(() => {
    matchSumm();
  }, [operatorArr]);

  const checkOperators = () => {
    const emptyOperatorsSum = Object.values(fields).filter(item => item.admin_user_id === null);
    return emptyOperatorsSum.length > 1;
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onClose={closeModal}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Create new rule'}
            style={{
              wrapper: {
                maxWidth: '600px',
              },
            }}
            rightContent={
              <div className={cx('content')}>
                <div
                  className={cx('content-form', 'popupForm', 'sales-rules')}
                  ref={contentToScroll}
                >
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Tabs className={cx('tabs')}>
                      <TabList>
                        <Tab>Rule Settings</Tab>
                      </TabList>

                      <TabPanel className={cx('tabs-panel')}>
                        <Container ref={dynamicHeightContent}>
                          <Row>
                            <Col md={12}>
                              <FormInput
                                label='Rule name'
                                id='rule_name'
                                control={control}
                                name='name'
                                placeholder='Rule name'
                                rules={{
                                  required: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <JokerSelect
                                label='Priority'
                                control={control}
                                id='priority'
                                placeholder={'--Select--'}
                                name='priority'
                                rightaligned={true}
                                options={priorityOpt}
                                rules={{
                                  required: true,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row ref={countrySelectRef}>
                            <Col md={12}>
                              <JokerMultiSelect
                                label='Country'
                                control={control}
                                id='country'
                                name='country_ids'
                                onMenuOpen={() => {
                                  setTimeout(
                                    () =>
                                      contentToScroll.current.scrollTo({
                                        top: countrySelectRef.current.offsetTop - 25,
                                        left: 0,
                                        behavior: 'smooth',
                                      }),
                                    10,
                                  );
                                }}
                                placeholder={'--Select one or multiple options--'}
                                rightaligned={true}
                                options={countryList}
                                isRequired
                              />
                            </Col>
                          </Row>
                          <Row ref={langSelectRef}>
                            <Col md={12}>
                              <JokerMultiSelect
                                label='Language'
                                control={control}
                                id='language'
                                onMenuOpen={() => {
                                  setTimeout(
                                    () =>
                                      contentToScroll.current.scrollTo({
                                        top: langSelectRef.current.offsetTop - 25,
                                        left: 0,
                                        behavior: 'smooth',
                                      }),
                                    10,
                                  );
                                }}
                                name='language_ids'
                                placeholder={'--Select one or multiple options--'}
                                rightaligned={true}
                                options={langList}
                              />
                            </Col>
                          </Row>
                          <Row ref={partnerSelectRef}>
                            <Col md={12}>
                              <PaginateSelect
                                isMulti={true}
                                label='Affiliate'
                                control={control}
                                id='partner'
                                name='partner_ids'
                                onChange={(page, search) => fetchPartners(page, search)}
                                isRequired
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} className={cx('chip-col')}>
                              <div className={cx('label-wrap')}>
                                <label className={cx('label')}>Source</label>
                              </div>
                              <MuiChipsInput
                                value={source}
                                className={cx('chip-col__chips')}
                                fullWidth
                                hideClearAll
                                size="small"
                                placeholder={'Source'}
                                onChange={(chips: MuiChipsInputChip[]): void => {
                                  setSource(chips)
                                }}
                              />
                            </Col>
                          </Row>
                          {fields.map((item, index) => (
                            <Row key={item.id}>
                              <Col md={5}>
                                <PaginateSelect
                                  isMulti={false}
                                  label='Operators'
                                  control={control}
                                  id='operator'
                                  name={`operators.${index}.admin_user_id`}
                                  selectedArr={getValues('operators')}
                                  onSelectChange={() => {
                                    if (checkIsLastSelectInArrayIsEmpty(index) && fields.length < 10) {
                                      append({
                                        ration: null,
                                        admin_user_id: null,
                                      });
                                    }
                                  }}
                                  onChange={(page, search) => {
                                    return fetchOperatorsSalesRules(page, search);
                                  }}
                                  onMenuOpen={() => {
                                    setTimeout(
                                      () =>
                                        contentToScroll.current.scrollTo({
                                          top: dynamicHeightContent.current.clientHeight,
                                          left: 0,
                                          behavior: 'smooth',
                                        }),
                                      100,
                                    );
                                  }}
                                  isRequired={index === 0}
                                />
                              </Col>
                              <Col md={3}>
                                <FormInput
                                  label='Ratio'
                                  id={`operators.${index}.ration`}
                                  control={control}
                                  type='number'
                                  step={1}
                                  min={1}
                                  max={10}
                                  name={`operators.${index}.ration`}
                                  disabled={!getValues(`operators.${index}.admin_user_id`)}
                                  rules={{
                                    required: !!getValues(`operators.${index}.admin_user_id`)
                                  }}
                                />
                              </Col>
                              <Col md={3}>
                                <FormInput
                                  label='Persent'
                                  id={`operators.${index}.persent`}
                                  control={control}
                                  type='text'
                                  step={1}
                                  min={0}
                                  name={`operators.${index}.persent`}
                                  value={
                                    operatorArr[index]
                                      ? ((operatorArr[index] / summ) * 100).toFixed(2)
                                      : '0.00'
                                  }
                                  placeholder='--'
                                  disabled={true}
                                />
                              </Col>
                              <Col md={1}>
                                {getLastIndex() !== index || fields.length === 10 ? (
                                  <div
                                    className={cx('delete-col')}
                                    onClick={() => {
                                      remove(index);
                                      if (fields.length === 10 && checkOperators()) {
                                        return;
                                      }
                                      if (fields.length === 10) {
                                        append({
                                          ration: null,
                                          admin_user_id: null,
                                        });
                                      }
                                    }}
                                  >
                                    <Trash size={20} />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Col>
                            </Row>
                          ))}
                        </Container>
                        <div className={cx('content-controls')}>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className={cx('controls__buttons')}>
                                  <Button
                                    buttonText='Cancel'
                                    buttonType='outline'
                                    size='big'
                                    type='button'
                                    onClick={closeModal.bind(undefined, close)}
                                  />
                                  <LoaderButton
                                    buttonType='primary'
                                    size='big'
                                    showSpinner={isLoading}
                                    disabled={isLoading || (!isDirty && !sourceIsChange)}
                                    buttonText={'Create new rule'}
                                    type='submit'
                                    onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateSalesRulesPopup;
