import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import DatePickerRange from 'components/ui/DatePickerRange';
import { isFormChange } from '../../../utils/isFormChange';
import { KYCDocumentsFilter } from 'models/KYCDocuments/KYCDocsPage/Filter';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: KYCDocumentsFilter) => void;
  reloadFilters: boolean;
  categoryOptions: ListValue[];
  typesOptions: ListValue[];
  changeDocumentCategory: (val: ListValue[]) => void;
  queryMessage: string,
  setQueryMessage: Dispatch<SetStateAction<string>>
}

const KycDocumentsFilter = (props: Props) => {
  const {
    onSubmit,
    reloadFilters,
    categoryOptions,
    typesOptions,
    changeDocumentCategory,
    queryMessage,
    setQueryMessage
  } = props;

  const componentMounted = useRef(true);
  const documentSearchOptions = useSearchCategoriesOptions('document');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      created_at: '',
      kyc_category: [],
      kyc_type: [],
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { isDirty, isValid, dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, documentSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        created_at: data['created_at'],
        kyc_category: getValue(data['kyc_category']),
        kyc_type: getValue(data['kyc_type']) && getValue(data['kyc_type']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    // setTypesOptions([]);
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={cx('filters')}
    >
      <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
        <>
          <CrmSearchCategorySelect
            id='query_category'
            name='query_category'
            control={control}
            options={documentSearchOptions}
            onSelectChange={() => {
              queryMessage && setQueryMessage('');
              const { query } = getValues();
              if (query) submit(getValues());
            }}
          />
          <CrmSearch
            id='query'
            name='query'
            control={control}
            placeholder={dynamicSearchPlaceholder}
            onSearch={() => submit(getValues())}
            handleChange={(value: string) => {
              queryMessage && setQueryMessage('');
              if (value?.length === 0) submit(getValues());
            }}
          />
        </>
      </ComplexSearchBar>
      <Row>
        <Col xs={12} md={12} lg={6} xl={3}>
          <JokerMultiSelect
            label={'Category'}
            control={control}
            id="kyc_category"
            name="kyc_category"
            options={categoryOptions}
            onChange={(value) => changeDocumentCategory(value)}
          />
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          <JokerMultiSelect
            label={'Document type'}
            control={control}
            id="kyc_type"
            name="kyc_type"
            options={typesOptions}
          />
        </Col>
        <Col xs={12} md={12} lg={6} xl={3}>
          <DatePickerRange
            name="created_at"
            control={control}
            label={'Upload data range'}
            placeholder="From - To"
          />
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => {
                resetForm()
                submit(getValues())
              }}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default KycDocumentsFilter;
