import React from "react";
import { components } from "react-select";
import { OptionProps } from "react-select/dist/declarations/src/components/Option";
import { IOptions } from "../../../../../models/components/Select/Select";
import { ColorSetCircles } from "../ColorSetCircles/ColorSetCircles";
import './ColorSetOption.scss';

export const ColorSetOption = (props: OptionProps<IOptions & { colorSet: string[] }>) => {
  return (
    <components.Option {...props} className="color-set-option__container">
      <ColorSetCircles colorSet={props.data.colorSet}/>
      <span className="single-value__text">{props.data.label}</span>
    </components.Option>
  );
};
