import { getCountryList } from 'api/country';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button/Button';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import { LeadsFilter } from 'models/Leads/LeadsFilter';
import { ArrowsClockwise } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import useDynamicSearchPlaceholder from 'utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from 'utils/useGetSearchCategoriesOptions';
import { getLeadStatuses } from '../../../../api/leads';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import PaginateSelect from '../../../../components/ui/PaginateSelect';
import { clearEmptyFilter } from "../../../../utils/clearEmptyFilter";
import { isFormChange } from '../../../../utils/isFormChange';
import { fetchLanguagesPaginate } from '../../../../utils/managment/fetchData';
import styles from './lead-filter.module.scss';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: LeadsFilter, hasReindex?: boolean) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

const LeadFilter = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const leadsSearchOptions = useSearchCategoriesOptions('lead');
  const [countyOpt, setCountryOpt] = useState([]);
  const [searchPlaceholder, setSearchPlaeholder] = useState('Search');
  const [leadStatuses, setLeadStatuses] = useState([]);

  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      languages: [],
      countries: [],
      account_statuses: [],
      created_at: '',
      note_last: '',
      query: '',
      query_category: defaultQueryCategoryOption,
    };
  };
  const { handleSubmit, reset, getValues, control, formState, watch } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, leadsSearchOptions);

  const prepareFiltersData = (data: any) => {
    const postData = {
      filter: {
        languages: getValue(data['languages']),
        countries: getValue(data['countries']),
        account_statuses: getValue(data['account_statuses']),
        created_at: data['created_at'],
        note_last: data['note_last'],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data, hasReindex = false) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData, hasReindex);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    fetchCountryList();
    getLeadStatuses()
      .then(response => {
          const statuses = response.data.data.map(item =>
            ({
              value: item.id,
              label: item.name,
            }),
          );
          setLeadStatuses(statuses);
        },
      );


    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      if (componentMounted.current) {
        const opt = res.data.data.map((opt) => ({
          value: opt.iso_3166_2,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOpt([{ value: '0', label: 'Other' }].concat(opt));
      }
    });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit((data) => submit.call(null, data));
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
                  <>
                    <CrmSearchCategorySelect
                      id='query_category'
                      name='query_category'
                      control={control}
                      options={leadsSearchOptions}
                      onSelectChange={() => {
                              const { query } = getValues();
                        if (query) submit(getValues());
                      }}
                    />
                    <CrmSearch
                      id='query'
                      name='query'
                      control={control}
                      placeholder={dynamicSearchPlaceholder}
                      onSearch={() => submit(getValues())}
                      handleChange={(value: string) => {
                              if (value?.length === 0) submit(getValues());
                      }}
                    />
                  </>
                </ComplexSearchBar>
              </Col>
            </Row>
          </Col>
          <Col md={12} xs={12} lg={12} xl={6}>
            <Row>
              <Col md={6}>
                <PaginateSelect
                  isSearchable={true}
                  isMulti={true}
                  placeholder={'Any'}
                  label='Languages'
                  control={control}
                  id='languages'
                  name='languages'
                  onChange={(page, search) => fetchLanguagesPaginate(page, search)}
                />
              </Col>
              <Col md={6}>
                <JokerMultiSelect
                  label='Country'
                  control={control}
                  id='country'
                  name='countries'
                  options={countyOpt}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <DatePickerRange
                  name='note_last'
                  control={control}
                  label='Last note date'
                  placeholder='From - To'
                />
              </Col>
              <Col md={6}>
                <DatePickerRange
                  name='created_at'
                  control={control}
                  label='Registration date range'
                  placeholder='From - To'
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} xs={12} lg={12} xl={6}>
            <Row>
              <Col md={6}>
                <JokerMultiSelect
                  label='Account status'
                  control={control}
                  id='account_status'
                  name='account_statuses'
                  options={leadStatuses}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color={reloadFilters ? 'var(--black)' : 'var(--white)'} size={18} />}
              disabled={reloadFilters}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues(), true);
              }}
            />
            <Button
              buttonText='Reset'
              type='reset'
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText='Apply'
              type='submit'
              onClick={handleSubmit((data) => submit.call(null, data))}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeadFilter;
