import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './formInput.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import { Eye, EyeSlash } from 'phosphor-react';

const cx = classNames.bind(styles);

const FormInput = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState<boolean>(false);
  const [showValue, setShowValue] = useState<boolean>(true);
  const onSelectChange = (value): void => {
    field.onChange(value);
    props.onSelectChange?.();
    props.onChange?.(value.target.value);
  };

  const handleShowValue = (): void => {
    setShowValue(!showValue);
  };

  useEffect((): void => {
    if (props.hideValue === true) {
      setShowValue(false);
    }
  }, [props.hideValue]);

  return (
    <>
      <div className={cx('label-wrap')} style={props.labelStyle}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        <div className={cx('icon-container')}>
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
      </div>
      <div
        style={
          props.additional && focus
            ? { borderColor: 'var(--main-color)' }
            : { backgroundColor: 'transperent' }
        }
        className={props.additional ? cx('withAdditional') : cx('')}
      >
        <div className={props.errors ? cx('input-wrap', 'error') : cx('input-wrap')}>
          {props.additional && <div className={cx('generate-btn')}>{props.additional}</div>}
          <input
            {...field}
            // {...props}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            name={props.name}
            className={!showValue ? cx('input', 'hidden') : props.errors ? cx('input', 'error') : cx('input')}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            // value={props.value || (field.value === null ? '' : field.value)}
            value={
              props.inputmode === 'numeric'
                ? // field?.value?.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1') // Только цифры и точка
                  field?.value?.replace(/[^0-9]+/g, '').replace(/(\..*)\./g, '$1') // Только цифры
                : props.value || (field.value === null ? '' : field.value)
            }
            onFocus={
              props.additional
                ? (): void => {
                    setFocus(true);
                  }
                : props.onFocus
            }
            onBlur={() => setFocus(false)}
            onChange={onSelectChange}
            maxLength={props.maxLength}
          />
          {props.hideValue && (
            <span className={cx('input-icon')}>
              {!showValue ? (
                <Eye size={24} onClick={handleShowValue} />
              ) : (
                <EyeSlash size={24} onClick={handleShowValue} />
              )}
            </span>
          )}
          {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
        </div>
      </div>
    </>
  );
};

export default FormInput;
