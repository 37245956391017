import classNames from 'classnames/bind';
import { Fingerprint } from 'phosphor-react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import FormInput from '../../../components/ui/FormInput';
import PasswordInput from '../../../components/ui/PasswordInput/PasswordInput';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { login, selectAuthIsAuthorized, selectAuthIsLoading } from '../../../store/slice/authSlice';
import { getUserInfoMethod } from '../../../store/slice/userInfoSlice';
import styles from './signin.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { getLogo } from 'constants/selectors';

const cx = classNames.bind(styles);

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const intl = useIntl();
  const isLoading = useAppSelector(selectAuthIsLoading);
  const isAuthorized = useAppSelector(selectAuthIsAuthorized);

  const logo = useAppSelector(getLogo);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    dispatch(
      login({
        email: data.email,
        password: data.password,
      }),
    ).then((res) => {
      if (res && res.meta.requestStatus === 'fulfilled') {
        navigate('/search-clients');
        dispatch(getUserInfoMethod());
      }
    });
  };

  const changingTabTitle = () => {
    const title = intl.formatMessage({
      id: 'auth.signIn.title',
      defaultMessage: 'Sign in',
    });
    document.title = title;
  };

  useEffect(() => {
    changingTabTitle();
    if (isAuthorized) {
      navigate('/search-clients');
    }
  }, []);

  return (
    <>
      <div className={cx('page')}>
        <div className={cx('login', 'auth')}>
          <div className={cx('logo')}>
            <img src={logo} alt="logo" />
          </div>
          <div className={cx('login__col', 'auth__descr')}>
            <div className={cx('auth__descr-img')}>
              <Fingerprint size={170} />
            </div>
            <div className={cx('auth__descr-text')}></div>
          </div>
          <div className={cx('login__col')}>
            <div className={cx('login__header')}>
              <div className={cx('login__title')}>
                <FormattedMessage id="auth.signIn.title" defaultMessage="Sign in" />
              </div>
            </div>
            <div className={cx('login__content')}>
              <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? <Preloader /> : null}
                <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
                  <Row className={cx('form__line')}>
                    <Col md={12}>
                      <FormInput
                        name="email"
                        type="email"
                        label={
                          <FormattedMessage
                            id="auth.signIn.fieldName.email"
                            defaultMessage="E-mail"
                          />
                        }
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.requiredField"
                              defaultMessage="Field is required"
                              values={{
                                fieldName: 'E-mail',
                                code: (fieldName) => <span>{fieldName}</span>,
                              }}
                            />
                          ),
                        }}
                        errors={errors.email}
                      />
                    </Col>
                    <Col md={12}>
                      <PasswordInput
                        name="password"
                        type="password"
                        label={
                          <FormattedMessage
                            id="auth.signIn.fieldName.password"
                            defaultMessage="Password"
                          />
                        }
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.passwordMessage_7"
                              defaultMessage="Password field is required"
                            />
                          ),
                          // validate: PASSWORD_INPUT_VALIDATION,
                        }}
                        // errors={errors.password}
                      />
                    </Col>
                  </Row>
                  <div className={cx('button-wrap')}>
                    <Link to="/forgot-password" className={cx('text-link')}>
                      <FormattedMessage
                        id="auth.signIn.forgotPassword"
                        defaultMessage="Forgot your password?"
                      />
                    </Link>
                    <Button
                      type="submit"
                      buttonText={
                        <FormattedMessage id="auth.signIn.button" defaultMessage="Sign in" />
                      }
                      size="big"
                    />
                  </div>
                </Container>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('copyright')}>
        © {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default SignIn;
