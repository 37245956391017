import { APIFindClientsResult, APIGetClientInfoResponse } from 'models/API/Clients/Clients';
import API from '../api';
import { APIMessageResponse } from 'models/API/Common';
import { APIGetDynamicStatusByKeyResponse, APIGetDynamicStatusesResponse } from 'models/API/Clients/DynamicStatuses';

// unused method
export const getClientsList = (params) => API.get(`/api/v1/users?${params}`);

export const findClients = (params?, data?) => API.post<APIFindClientsResult>(`/api/v1/search/users?${params}`, data);
export const findClientsList = (params, query) =>
  API.post<APIFindClientsResult>(`/api/v1/search/users?${params}`, query && { query: query });
  
// unused method
export const findClientsDefault = (params, data) =>
  API.post(`/api/v1/search/users?page=1&per_page=100`, data);

export const getClientInfo = (id) => API.get<APIGetClientInfoResponse>(`/api/v1/users/${id}`);

// unused method
export const getAddressClient = (id) => API.get(`/api/v1/users/${id}/address`);

// response unused
export const changeAcquisitionStatus = (data) => API.post(`/api/v1/users/bulk/change-status
`, data);

// response unused
export const bulkActionClientToTeam = (data) => API.post(`/api/v1/users/bulk/to-teams`, data);

// response unused
export const bulkActionClientToBuffer = (data) => API.post(`/api/v1/users/bulk/move`, data);

// response unused
export const changePasswordForClient = (id, data) =>
  API.post(`/api/v1/change/password/users/${id}`, data);

export const resetPasswordForClient = (id) => API.get<APIMessageResponse>(`/api/v1/reset/password/users/${id}`);

export const clientDynamicStatuses = () => API.get<APIGetDynamicStatusesResponse>(`/api/v1/users/status-keys`);

export const clientDynamicStatusesByKey = (key) => API.get<APIGetDynamicStatusByKeyResponse>(`/api/v1/users/statuses?key=${key}`);

export const setStatusToUser = (id, statusId) => API.post<APIMessageResponse>(`/api/v1/users/${id}/status/${statusId}`);
export const getBlockedStatus = () => API.get(`/api/v1/blocked-status`);
export const blockedAcc = (userId, data) => API.post<APIMessageResponse>(`/api/v1/users/${userId}/blocked`, data);

// response unused
export const unBlockedAcc = (userId) => API.get(`/api/v1/users/${userId}/unblocked`);

export const verifyAcc = (userId) => API.post<APIMessageResponse>(`/api/v1/users/${userId}/verified`);

// response unused
export const changeOperatorInTransaction = (transactionId, data) => API.put(`api/v1/deposit/transaction/${transactionId}/change-operator`, data);

// response unused
export const deleteClient = (userId) => API.delete(`/api/v1/users/${userId}`);

export const enableGlobalDemoAccounts = () => API.post(`/api/v1/set-setting/allowDemoAccounts`);

export const disableGlobalDemoAccounts = () => API.post(`/api/v1/set-setting/disallowDemoAccounts`);

export const enableClientDemoAccounts = (id: string) => API.post(`/api/v1/users/${id}/allowDemoAccount`)

export const disableClientDemoAccounts = (id: string) => API.post(`/api/v1/users/${id}/disallowDemoAccount`);

type AutologinResponse = {
  url: string;
}

export const getClientAutologinLink = (id: number) => API.get<AutologinResponse>(`/api/v1/users/autologin/${id}`)