import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import Popup from 'reactjs-popup';
import { createCallbacks } from '../../../../../api/callbaks';
import Button from '../../../../../components/ui/Button';
import DatePicker from '../../../../../components/ui/DatePicker';
import JokerMultiSelect from "../../../../../components/ui/JokerMultiSelect";
import LoaderButton from '../../../../../components/ui/LoaderButton';
import PaginateSelect from '../../../../../components/ui/PaginateSelect';
import PopupTemplate from '../../../../../components/ui/PopupTemplate';
import { fetchCallbacksToPopulateReminders } from "../../../../../store/slice/reminders";
import { fetchOperators } from '../../../../../utils/managment/fetchData';
import { notify } from '../../../../../utils/notify';
import { optionsReminder } from "./constants";
import { remindersValidation, timeValidation } from "./validations";
import { addYears } from "date-fns";
import styles from './callbacks.module.scss';

const cx = classNames.bind(styles);

const AddCallbackPopup = ({ triggerBtn, updateComponent, userId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    // updateComponent();
    closeModal();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      admin_user_id: data.admin_user_id.value,
      user_id: userId,
      time: data && data.time ? Math.round(data.time / 1000) : null,
      reminders: data.reminders.map(item => item.value),
      status: data && data.status ? data.status : null,
    };

    createCallbacks(sendData)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          notify({
            type: 'success',
            message: 'Callback created successfully',
            timeOut: 3000,
          });
          updateComponent();
          closeModal(close);
          dispatch(fetchCallbacksToPopulateReminders());
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Add new callback'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'addNewCallbackForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <PaginateSelect
                            label='Operator'
                            control={control}
                            id='admin_user_id'
                            name='admin_user_id'
                            onChange={(page, search) => fetchOperators(page, search)}
                            isRequired={true}
                            rules={{ required: ' Field is required' }}
                            errors={errors.admin_user_id}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <DatePicker
                            name='time'
                            control={control}
                            label='Callback date & time'
                            dateFormat='MMMM d, yyyy HH:mm'
                            showTimeInput
                            minDate={new Date()}
                            maxDate={addYears(new Date(), 10)}
                            autoComplete='off'
                            rules={{
                              required: ' Field is required',
                              validate: { timeValidation }
                            }}
                            errors={errors.time}
                            onChange={() => isSubmitted && getValues("reminders")?.length && trigger('reminders')}
                          />
                        </Col>
                        <Col xs={6}>
                          <JokerMultiSelect
                            name='reminders'
                            placeholder='Reminders'
                            label='Reminders'
                            control={control}
                            options={optionsReminder}
                            rules={{
                              required: ' Field is required',
                              validate: { isFuture: remindersValidation(getValues) }
                            }}
                            errors={errors.reminders}
                            isRequired={true}
                            hideSearch={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText='Cancel'
                                buttonType='outline'
                                size='big'
                                type='button'
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <LoaderButton
                                buttonType='primary'
                                size='big'
                                showSpinner={isLoading}
                                // disabled={isLoading || !nameField}
                                buttonText={'Create'}
                                type='submit'
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default AddCallbackPopup;
