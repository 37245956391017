import React from 'react';
import PropTypes from 'prop-types';
import Blanket from './Blanket';
import Menu from './Menu';

export default function Dropdown({ children, isOpen, target, onClose, rightaligned }) {
  return (
    <div style={{ position: 'relative' }}>
      {target}
      {isOpen ? <Menu rightaligned={rightaligned.toString()}>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  target: PropTypes.node,
  onClose: PropTypes.func,
  rightaligned: PropTypes.bool,
};
Dropdown.defaultProps = {
  children: null,
  isOpen: false,
  target: null,
  onClose() {},
};
