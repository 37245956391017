import classNames from 'classnames/bind';
import React from 'react';
import styles from './page.module.scss';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
  styles?: React.CSSProperties;
  children?: React.ReactElement;
}

const Page = (props: Props) => {
  const { title, children, styles } = props;

  return (
    <div className={cx('page')} style={styles}>
      {title ? <h2 className={cx('page__title')}>{title}</h2> : ''}
      <div className={cx('page__content')}>{children}</div>
    </div>
  );
};

export default Page;
