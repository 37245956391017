import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from 'components/ui/FormInput';
import Select from 'components/ui/Select';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import { updateLeadContacts } from 'api/leads';
import { getDepartmentRolesMethod } from '../../../../../api/managements/operators';
import JokerSelect from '../../../../../components/ui/JokerSelect';
import JokerSelectWithSearch from '../../../../../components/ui/JokerSelectWithSearch';
import LoaderButton from '../../../../../components/ui/LoaderButton';
import { updateOperatorProfileMethod } from '../../../../../api/operators';
import { useParams } from 'react-router-dom';
import { addRoleMethod, deleteRoleMethod } from '../../../../../api/operator';
import { deleteDepartaments } from '../../../../../api/departament';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  departmentsList: ListValue[];
  setOpenForm: (val: boolean) => void;
  updateComponent: () => void;
}

const AddDepartmentForm = ({ departmentsList, setOpenForm, updateComponent }) => {
  const params = useParams();
  const setDefaultValues = () => {
    return {
      department_id: undefined,
      role: undefined,
    };
  };
  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      department_id: data?.department_id.value,
      role: data?.role?.label,
      admin_user_id: params.id,
    };
    addRoleMethod(sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          reset(setDefaultValues());
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const setDefaultValuesRole = () => {
    return {
      department_id: getValues('department_id'),
      role: '',
    };
  };
  const getRolesList = (department) => {
    reset(setDefaultValuesRole());
    getDepartmentRolesMethod(department.value).then((res) => {
      if ((res.status = 200)) {
        const list = res.data.data.roles.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        setRoleList(list);
      }
    });
  };
  return (
    <form className={cx('form-department')} onSubmit={handleSubmit(onSubmit)}>
      <Row className={cx('form__line')}>
        <Col md={4}>
          <div className={cx('form__line')}>
            <JokerSelect
              isRequired={false}
              label={'Department'}
              onSelectChange={() => {
                getRolesList(getValues('department_id'));
              }}
              control={control}
              id="department_id"
              name={'department_id'}
              placeholder={'--Select--'}
              rightaligned={true}
              options={departmentsList}
            />
            {/* <Button buttonText="Verify" /> */}
          </div>
        </Col>
        <Col md={4}>
          <div className={cx('form__line')}>
            <JokerSelectWithSearch
              isAbsolute={true}
              label={'Role'}
              control={control}
              id="role"
              name="role"
              placeholder={'--Select--'}
              rightaligned={true}
              options={roleList}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className={cx('btn-wrapper')}>
            <LoaderButton
              buttonType="primary"
              size="small"
              showSpinner={isLoading}
              disabled={isLoading || !isDirty}
              buttonText={'Save'}
              type="submit"
              onClick={(data) => handleSubmit(onSubmit.bind(data))}
            />
            <Button
              buttonText="Cancel"
              buttonType="outline"
              size="small"
              type="button"
              onClick={() => {
                setOpenForm(false);
              }}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default AddDepartmentForm;
