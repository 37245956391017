import './SystemStylePreview.scss';
import CalculatorSvg from "../../../../../components/ui/Icons/CalculatorSvg";
import ChartNetworkSvg from "../../../../../components/ui/Icons/ChartNetworkSvg";
import EuroSvg from "../../../../../components/ui/Icons/EuroSvg";
import FolderSvg from "../../../../../components/ui/Icons/FolderSvg";
import UsdSvg from "../../../../../components/ui/Icons/UsdSvg";

export const SystemStylePreview = ({ className, logo }) => {
  return (
    <div className={`system-style-preview ${className}`}>
      <header className="header">
        <div className="header-logo">
          <img src={logo} alt='logo'/>
        </div>
        <div className="info">
          <div className="info__eur">
            <div className="info__eur-icon">
              <EuroSvg/>
            </div>
            <div className="info__eur-balance">
              <span>Balance</span>
              <span>14,999.14</span>
            </div>
            <div className="info__eur-credit">
              <span>Credit</span>
              <span>0.00</span>
            </div>
          </div>
          <div className="info__usd">
            <div className="info__usd-icon">
              <UsdSvg/>
            </div>
            <div className="info__usd-balance">
              <span>Balance</span>
              <span>14,999.14</span>
            </div>
            <div className="info__usd-credit">
              <span>Credit</span>
              <span>0.00</span>
            </div>
          </div>
          <div className="info__deposit">
            <button>Deposit</button>
          </div>
          <div className="info__avatar"></div>
        </div>
      </header>
      <div className="preview-container">
        <div className="nav-logo">
          <img src={logo} alt='logo'/>
        </div>
        <nav className="navbar">
          <ul>
            <li><CalculatorSvg/></li>
            <li><ChartNetworkSvg/></li>
            <li><FolderSvg/></li>
          </ul>
        </nav>
        <main className="main-content">
          <h3>Payments</h3>
          <div className="table-controls">
            <div className="tabs">
              <button key="transactions" className="active">
                Transactions
              </button>
              <button key="statuses">
                Payment System Statuses
              </button>
            </div>
            <button className="button-dropdown">
              Make Transaction
              <div className="button-dropdown__icon"></div>
            </button>
          </div>
          <div className="table">
            <table>
              <thead>
              <tr>
                <th>Transactions</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Payment Method</th>
                <th>Gateway</th>
                <th>Trading Account</th>
                <th>Date & Time</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Some name</td>
                <td>6112501</td>
                <td>0.00 USD</td>
                <td>0.00 USD</td>
                <td>100</td>
                <td>100</td>
                <td>24.12.2022</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Some name</td>
                <td>6112501</td>
                <td>0.00 USD</td>
                <td>0.00 USD</td>
                <td>100</td>
                <td>100</td>
                <td>24.12.2022</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Some name</td>
                <td>6112501</td>
                <td>0.00 USD</td>
                <td>0.00 USD</td>
                <td>100</td>
                <td>100</td>
                <td>24.12.2022</td>
                <td>100</td>
              </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};