import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames/bind';
import { ArrowsClockwise } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import { useSelector } from 'react-redux';
import { getTransactionsTypeMethods } from '../../../../../../api/payments';
import Button from '../../../../../../components/ui/Button';
import { ComplexSearchBar } from "../../../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect, defaultQueryCategoryOption
} from "../../../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import DatePickerRange from '../../../../../../components/ui/DatePickerRange';
import FormInput from '../../../../../../components/ui/FormInput';
import JokerMultiSelect from '../../../../../../components/ui/JokerMultiSelect';
import JokerSelect from '../../../../../../components/ui/JokerSelect';
import { CURRENCY_OPTIONS } from '../../../../../../constants/clients/filters.const';
import { MOBILE_SIZE_WIDTH } from '../../../../../../constants/mobile';
import { getEnableMT } from '../../../../../../constants/selectors';
import useDynamicSearchPlaceholder from "../../../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';

const cx = classNames.bind(styles);

const PaymentsFilter = ({ onSubmit, reloadFilters, userId, transactionTypeOpt, queryMessage, setQueryMessage}) => {
  const [fieldData, setFieldData] = useState([]);
  const [paymentMethodOpt, setPaymentMethodOpt] = useState([]);
  // const [accountTypesOpt, setAccountTypesOpt] = useState([]);

  const [oldFilters, setOldFilters] = useState(true);
  const componentMounted = useRef(true);
  const width = useWindowWidth();
  const ENABLE_MT = useSelector(getEnableMT);
  const paymentsSearchOptions = useSearchCategoriesOptions('depositTransaction');

  const isMobile = () => width < MOBILE_SIZE_WIDTH;

  const getDefaultValue = (dynamic?) => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      payment_method: [],
      payment_type: [],
      transaction_type: [],
      transaction_status: [],
      status_updated_at: '',
      currencyIds: [],
      accountType: { value: 'any', label: 'Any' },
      first_time_deposit: { value: 'any', label: 'Any' },
      amount: {
        from: '',
        to: '',
      },
      created_at: '',
      custom_filters: '',
      client_filter: fieldData,
      user_id: [+userId],
    };
  };

  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, paymentsSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        payment_method: getValue(data['payment_method']),
        payment_type: getValue(data['payment_type']),
        transaction_type: getValue(data['transaction_type']),
        transaction_status: getValue(data['transaction_status']),
        status_updated_at: getDateRange(data['status_updated_at']),
        currencyIds: getValue(data['currencyIds']),
        accountType: getValue(data['accountType']) ? [getValue(data['accountType'])] : '',
        first_time_deposit: getValue(data['first_time_deposit']),
        amount: getNumberRange(data['amount']),
        created_at: getDateRange(data['created_at']),
        user_id: [+userId],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    fetchPaymentsMethodList();
    // fetchTradingAccTypesList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchPaymentsMethodList = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.deposit.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setPaymentMethodOpt(opt);
      }
    });
    // getPaymentsMethodList().then((res) => {
    //   if (res && res.status === 200) {
    //     const opt = res.data.map((opt) => ({
    //       value: opt,
    //       label: opt,
    //     }));
    //     setPaymentMethodOpt(opt);
    //   }
    // });
  };
  // const fetchTradingAccTypesList = () => {
  //   getTradingAccTypes().then((res) => {
  //     if (res && res.status === 200) {
  //       const opt = res.data.data.map((opt) => ({
  //         value: opt,
  //         label: opt,
  //       }));
  //       setAccountTypesOpt(opt);
  //     }
  //   });
  // };

  const toggleHandler = () => {
    setFieldData([]);
    setOldFilters(!oldFilters);
    reset(getDefaultValue());
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getNumberRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (+data.from) {
      res['from'] = +data.from;
    }

    if (+data.to) {
      res['to'] = +data.to;
    }

    return res;
  };

  const getDateRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (data.from) {
      // res['from'] = timeToInteger(data['from']) / 1000;
      res['from'] = data['from'];
    }

    if (data.to) {
      // res['to'] = timeToInteger(data['to']) / 1000;
      res['to'] = data['to'];

    }

    return res;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar className="mt-0" message={reloadFilters ? '' : queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={paymentsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label='Payment method'
              control={control}
              id='payment_method'
              name='payment_method'
              options={paymentMethodOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label='Transaction type'
              control={control}
              id='transaction_type'
              name='transaction_type'
              options={transactionTypeOpt}
            />
          </Col>
          {ENABLE_MT ?
            <>
              <Col xl={3} lg={4} md={6}>
                <DatePickerRange
                  name='status_updated_at'
                  control={control}
                  label='Status modification date range'
                  placeholder='From - To'
                />
              </Col>
              <Col xl={3} lg={4} md={6}>
                <JokerMultiSelect
                  label='Currency'
                  control={control}
                  id='currencyIds'
                  name='currencyIds'
                  options={CURRENCY_OPTIONS}
                />
              </Col>
              <Col xl={3} lg={4} md={6}>
                <JokerMultiSelect
                  label='Transaction status'
                  control={control}
                  id='transaction_status'
                  name='transaction_status'
                  hideSearch={true}
                  options={[
                    {
                      value: 'pending',
                      label: 'Pending',
                    },
                    {
                      value: 'completed',
                      label: 'Completed',
                    },
                    {
                      value: 'canceled',
                      label: 'Canceled',
                    },
                  ]}
                />
              </Col>
            </> : null
          }
          <Col xl={3} lg={4} md={6}>
            <JokerSelect
              label='First time deposit'
              control={control}
              id='first_time_deposit'
              name='first_time_deposit'
              options={[
                { value: 'any', label: 'Any' },
                {
                  label: 'No',
                  value: 'no',
                },
                {
                  label: 'Yes',
                  value: 'yes',
                },
              ]}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <Row className={cx('range-wrapper')}>
              <Col md={6}>
                <FormInput
                  name='amount.from'
                  type='number'
                  placeholder='0.00'
                  min={0}
                  label='Amount'
                  control={control}
                />

                <span className={cx('range-divider')}>-</span>
              </Col>
              <Col md={6}>
                <FormInput
                  name='amount.to'
                  min={0}
                  type='number'
                  label='&nbsp;'
                  placeholder='0.00'
                  control={control}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name='created_at'
              control={control}
              label='Creation date range'
              placeholder='From - To'
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color='var(--white)' size={18}/>}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText='Reset'
              type='reset'
              onClick={() => resetForm()}
              disabled={!getDirtyFieldsLength()}
            />
            <Button
              buttonText='Apply'
              type='submit'
              onClick={handleSubmit(submit)}
              disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PaymentsFilter;
