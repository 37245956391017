import Popup from "reactjs-popup";
import { useEffect, useState } from "react";
import OperatorIcon from '../../../../assets/Svg/OperatorIcon';
import styles from './offices-table.module.scss'
import classNames from "classnames/bind";
import Button from "components/ui/Button";
import { getTeamForDeskMethod } from "api/hierarchy";
import { HierarchyTeam } from "models/Hierarchy/HierarchyData";
import { ManagementTeamPopup } from "../TeamsTable/Popup";

const cx = classNames.bind(styles);

type Props = {
  triggerElement: JSX.Element;
  deskID: number;
  deskName: string;
}

export const ManagementDeskPopup = (props: Props) => {
  const { triggerElement, deskID, deskName } = props;
  
  const [teams, setTeams] = useState<HierarchyTeam[] | null>(null);
  const [teamPopupOpened, setTeamPopupOpened] = useState(false);
  
  const fetchDeskTeams = () => {
    getTeamForDeskMethod(deskID).then(res => setTeams(res.data.data));
  }
  
  return (
    <Popup 
      modal 
      trigger={triggerElement} 
      onOpen={fetchDeskTeams} 
      closeOnDocumentClick={!teamPopupOpened} 
      closeOnEscape={!teamPopupOpened}
    >
      {(close) => (
        <div className={cx("management_desk_popup_container")}>
          <div className={cx("popup_title_block")}>
            <span>Team List for {deskName}</span>
            <i className={cx("close")} onClick={close} />
          </div>
          <div className={cx("management_desk_popup_content")}>
            {teams?.map(team => 
              <div className={cx("management_desk_popup_entry")}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                  <p key={`TEAM-${team.id}-title`} className={cx("title")}>Team {team.name}</p>
                  <p key={`TEAM-${team.id}-details`} className={cx("details")}>{team.operators_count} users</p>
                </div>
                <ManagementTeamPopup 
                  triggerElement={
                    <Button 
                      buttonType="outline" 
                      icon={<OperatorIcon />} 
                    />
                  }
                  teamID={team.id}
                  teamName={team.name}
                  onOpen={() => setTeamPopupOpened(true)}
                  onClose={() => setTeamPopupOpened(false)}
                  nested
                />
              </div>
            )}
            {teams?.length === 0 && <p>No teams</p>}
          </div>
        </div>
      )}
    </Popup>
  )
}