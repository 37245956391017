import Page from "../../Page";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { usePageTitle } from "../../../utils/usePageTitle";
import { DashboardLayout } from "../../UserView/DashboardLayout";
import { ThemesTab } from "./components/ThemesTab/ThemesTab";
import { LogosTab } from "./components/LogosTab/LogosTab";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { getStyles } from "../../../store/slice/visualSlice";
import './StyleOfTheSystem.scss';

const StyleOfTheSystem = () => {
	const dispatch = useAppDispatch();
	usePageTitle('Style of the system');

	useEffect(() => {
		dispatch(getStyles());
	}, [])

	return (
		<DashboardLayout>
			<Page title="Style of the system" styles={{padding: '1rem'}}>
				<div className='style-of-the-system-container'>
					<Tabs className='tabs'>
						<TabList>
							<Tab>Style</Tab>
							<Tab>Logos</Tab>
						</TabList>
						<TabPanel><ThemesTab/></TabPanel>
						<TabPanel><LogosTab/></TabPanel>
					</Tabs>
				</div>
			</Page>
		</DashboardLayout>
	);
};

export default StyleOfTheSystem;
