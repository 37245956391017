import classNames from 'classnames/bind';
import { XCircle, FolderSimplePlus } from 'phosphor-react';
import React, { FC, useEffect } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import { systemImageTypes } from "constants/systemStyles";
import styles from './style.module.scss';

const cx = classNames.bind(styles);

interface IDragDropUploadImage {
  src: string;
  onChange: (logo: string, key: string, setImages) => void;
  onRemove: (key: string) => void;
  name: string;
  maxFileSize?: number;
  acceptType?: string[];
  onError?: (error: any) => void;
}

const DragDropUploadImage: FC<IDragDropUploadImage> = ({
                                                         onChange,
                                                         onRemove,
                                                         src,
                                                         name,
                                                         onError,
                                                         maxFileSize =  1048576,
                                                         acceptType = null
}) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  useEffect(() => {
    src ? setImages([src]) : setImages([]);
  }, [src]);

  const handleChange = (imageList): void => {
    !images.length && setImages(imageList);
    onChange(imageList[0], name, setImages);
  };

  return (
    <div className={cx('drag-drop-upload-image')}>
      <ImageUploading
        multiple={false}
        value={images}
        onChange={handleChange}
        maxNumber={maxNumber}
        maxFileSize={maxFileSize}
        acceptType={acceptType || systemImageTypes}
        onError={onError}
        dataURLKey="data_url"
      >
        {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
          <div className={cx('wrapper-uploaderImage')} {...dragProps}>
            <div className={cx('file-uploader')}>
              <div className={cx('file-uploader__files')}>
                <div className={cx('file-uploader__area')}>
                  {images.length === 0 ? (
                    <>
                      <i className={cx('file-uploader__icon')}/>
                      <span className={cx('placeholder-title')}>Drag & Drop to Upload file</span>
                      <span className={cx('placeholder-separator')}>OR</span>
                      <button
                        className={cx('file-uploader__title')}
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                      >
                        Browse file
                        <FolderSimplePlus size={20} color="#722ED1"/>
                      </button>
                    </>
                  ) : (
                    imageList.map((image: ImageType, index) => (
                      <div key={index} className={cx('image-item')}>
                        <img src={image?.data_url ? image.data_url : image} alt=""/>
                        <div className={cx('image-item__btn-wrapper')}>
                          <button
                            className={cx('actionBtn')}
                            type="button"
                            onClick={() => {
                              onImageRemove(index);
                              onRemove(name)
                            }}
                          >
                            <XCircle size={16} color="red"/>
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {
              errors?.maxFileSize &&
              <span className={cx('error__maxFileSize')}>
                The selected file is larger than {Math.floor(maxFileSize / (1024 ** 2))} MB.
              </span>
            }
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
export default DragDropUploadImage;
