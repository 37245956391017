import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../utils/useGetSearchCategoriesOptions";
import styles from './operators-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import JokerSelect from '../../../../components/ui/JokerSelect';
import DatePickerRange from '../../../../components/ui/DatePickerRange';
import { isFormChange } from '../../../../utils/isFormChange';
import { ManagementOperatorsFilter } from 'models/Management/Operators/Filter';
import { ListValue } from 'models/ListValue';
import { CountryListValue } from 'models/Countries';

import {
  fetchDesksNoPagination,
  fetchTeamsNoPagination,
  fetchTeamsForDeskNoPagination,
} from '../../../../utils/managment/fetchData';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data?: ManagementOperatorsFilter) => void;
  reloadFilters: boolean;
  statusList: ListValue[];
  countryList: CountryListValue[];
  queryMessage: string,
};

const OperatorsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, statusList, countryList, queryMessage} = props;
  const componentMounted = useRef(true);
  const [desks, setDesks] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedDesks, setSelectedDesks] = useState<ListValue[]>([]);
  const operatorsSearchOptions = useSearchCategoriesOptions('operator');

  const fetchDesksList = async () => {
    let options = [];
    const response = await fetchDesksNoPagination(1);
    if (response.fullData.status === 200) {
      options = response.data;
    }
    setDesks(options);
    return { data: options };
  };

  const fetchTeamsList = async (desks: ListValue[]) => {
    let options = [];
    if (desks.length > 0) {
      const response = await fetchTeamsForDeskNoPagination(
        1,
        undefined,
        desks.map((desk) => desk.value),
      );
      options = response.data;
    } else {
      const response = await fetchTeamsNoPagination(1);
      options = response.data;
    }

    setTeams(options);
    return { data: options };
  };

  useEffect(() => {
    fetchDesksList();
    fetchTeamsList([]);
  }, []);

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      country_ids: [],
      created_at: '',
      statuses: undefined,
      deskIds: [],
      teamIds: [],
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState, resetField } =
    useForm({
      reValidateMode: 'onChange',
      defaultValues: getDefaultValue(),
    });
  const { isDirty, isValid, dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, operatorsSearchOptions);

  useEffect(() => {
    if (!watch().statuses) {
      setValue('statuses', statusList.find(s => s.value === 'active'));
    }
  }, [statusList])

  useEffect(() => {
    const newTeams = getValues().teamIds.filter(
      (item) =>teams.find((t) => t.value === item.value));    
    setValue('teamIds', [...newTeams]);
  }, [teams]);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        country_ids: getValue(data['country_ids'], 'country_ids'),
        created_at: { from: data['created_at'].from, to: data['created_at'].to },
        statuses: getValue(data['statuses'], 'statuses') && [getValue(data['statuses'], 'statuses')],
        deskIds: getValue(data['deskIds'], 'deskIds'),
        teamIds: getValue(data['teamIds'], 'teamIds'),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit({ filter: { statuses: ['active'] }})
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data, key) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    if (key === 'statuses') {
      return data.value || 'any';
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={operatorsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          {countryList && (
            <Col xl={3} lg={4} md={6}>
              <JokerMultiSelect
                label={'Country'}
                control={control}
                id="country_ids"
                name="country_ids"
                options={countryList}
              />
            </Col>
          )}
          {statusList && (
            <Col xl={3} lg={4} md={6}>
              <JokerSelect
                label={'Status'}
                control={control}
                id="statuses"
                name="statuses"
                options={statusList}
                placeholder="Select"
              />
            </Col>
          )}
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label={'Registration date range'}
              placeholder="From - To"
            />
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Desks"
              control={control}
              id="desks"
              name="deskIds"
              options={desks}
              ungroup
              onChange={(value) => {
                setSelectedDesks(value);
                fetchTeamsList(value);
              }}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Teams"
              control={control}
              id="teams"
              name="teamIds"
              options={teams}
              ungroup
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OperatorsFilter;
