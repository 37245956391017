import React from "react";

import './ComplexSearchBar.scss';

type ComplexSearchBarProps = {
  searchOnly?: boolean;
  className?: string;
  message?: string;
} & React.PropsWithChildren<unknown>;


export const ComplexSearchBar = ({ children, searchOnly, className, message}: ComplexSearchBarProps) => {
  const containerClassName = searchOnly
    ? 'search-bar-container search-bar-container--search-only'
    : 'search-bar-container';

  return (
    <div className="complex-search-bar-wrap">
      <div className={`complex-search-bar ${className ?? ''}`}>
        <div className={containerClassName}>
          {children}
        </div>
      </div>
      <div className={`complex-search-bar-message ${message ? 'has-message' : ''}`}>{message}</div>
    </div>
  );
};