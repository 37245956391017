import React, { useState } from "react";
import Button from "../../../Button";
import LoaderButton from "../../../LoaderButton";
import { ModalSizes, ModalTemplate } from "../JokerModalTemplate/JokerModalTemplate";
import { closeJokerModal, selectModalProps } from "../../../../../store/slice/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import './ConfirmationModal.scss';

type ConfirmationModalProps = {
    id: string;
    onConfirm: (props?: any) => Promise<void>;
    className?: string;
    size?: ModalSizes;
};

export const ConfirmationModal = ({ id, onConfirm, className, size}: ConfirmationModalProps) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(selectModalProps);
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = () => dispatch(closeJokerModal());

    const handleConfirm = async () => {
        setIsLoading(true);

        try {
            await onConfirm();
            dispatch(closeJokerModal());
        } catch (error) {
            //TODO: add error handler
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ModalTemplate id={id} title={data?.title} onClose={handleCloseModal} className={className} size={size}>
            <div className='confirmation-modal'>
                {data?.description ? <div className="confirmation-modal__description">{data.description}</div> : null}
                <div className="confirmation-modal__controls">
                    <Button
                      buttonText={data?.cancelTitle || 'No'}
                      buttonType="outline"
                      size="big"
                      type="button"
                      onClick={handleCloseModal}
                    />
                    <LoaderButton
                      buttonType="primary"
                      size="big"
                      showSpinner={isLoading}
                      disabled={isLoading}
                      buttonText={data?.confirmTitle || 'Yes'}
                      onClick={handleConfirm}
                    />
                </div>
            </div>
        </ModalTemplate>
    )
};
