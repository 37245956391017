import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../utils/useGetSearchCategoriesOptions";
import styles from './parnters-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import JokerSelect from '../../../../components/ui/JokerSelect';
import { FormattedMessage } from 'react-intl';
import DatePickerRange from '../../../../components/ui/DatePickerRange';
import { isFormChange } from '../../../../utils/isFormChange';
import { ManagementPartnersFilter } from 'models/Management/Partners/Filter';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: ManagementPartnersFilter) => void;
  reloadFilters: boolean;
  statusList: ListValue[];
  countryList: ListValue[];
  queryMessage: string,
}

const PartnersFilter = (props: Props) => {
  const { onSubmit, reloadFilters, statusList, countryList, queryMessage} = props;

  const componentMounted = useRef(true);
  const [isDataFetchedAfterChange, setIsDataFetchedAfterChange] = useState(false);
  const partnersSearchOptions = useSearchCategoriesOptions('partner');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      country_ids: [],
      created_at: '',
      statuses: undefined,
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { isDirty, isValid, dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, partnersSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        country_ids: getValue(data['country_ids']),
        created_at: { from: data['created_at'].from, to: data['created_at'].to },
        statuses: getValue(data['statuses']) && [getValue(data['statuses'])],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={partnersSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          {countryList && (
            <Col xl={3} lg={4} md={6}>
              <JokerMultiSelect
                label={'Country'}
                control={control}
                id="country_ids"
                name="country_ids"
                options={countryList}
              />
            </Col>
          )}
          {statusList && (
            <Col xl={3} lg={4} md={6}>
              <JokerSelect
                label={'Status'}
                control={control}
                id="statuses"
                name="statuses"
                options={statusList}
              />
            </Col>
          )}
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label={'Registration date range'}
              placeholder="From - To"
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PartnersFilter;
