import { IOptions } from "models/components/Select/Select";
import React from "react";
import { OptionProps } from "react-select/dist/declarations/src/components/Option";
import { ColorSetCircles } from "..";

import '../ColorSetOption/ColorSetOption.scss';

export const ColorSetSingleValue = (props: OptionProps<IOptions & { colorSet: string[] }>) => {
  return (
    <div className="single-value__container">
      <ColorSetCircles colorSet={props.data.colorSet}/>
      <span className="single-value__text">{props.data.label}</span>
    </div>
  );
};
