import * as React from "react"

const EuroSvg = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#F9F1FF"/>
    <path
      d="M10.136 19.832C10.136 19.352 10.168 18.872 10.2 18.424H7.832V15.928H10.776C12.312 11.416 16.504 8.472 21.496 8.472C26.104 8.472 30.104 10.808 31.8 15.16H27.416C26.232 12.92 24.152 11.8 21.496 11.8C18.392 11.8 15.896 13.336 14.68 15.928H21.496V18.424H13.976C13.912 18.872 13.88 19.352 13.88 19.832C13.88 20.312 13.912 20.792 13.976 21.24H21.496V23.736H14.68C15.928 26.36 18.392 27.896 21.496 27.896C24.152 27.896 26.232 26.776 27.416 24.536H31.8C30.104 28.888 26.104 31.192 21.496 31.192C16.504 31.192 12.312 28.28 10.776 23.736H7.832V21.24H10.2C10.168 20.792 10.136 20.312 10.136 19.832Z"
      fill="#722ED1"/>
  </svg>
)

export default EuroSvg;
