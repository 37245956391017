import React from 'react';
import { colors } from './theme.js';

export default function Menu(props) {
  const shadow = colors.neutral10a;
  const style = {
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
    marginTop: 8,
    position: 'absolute',
    zIndex: 2,
  };
  if (props.rightaligned) {
    style.right = 0;
  }
  return <div style={{ ...style }} {...props} />;
}
