import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../../../components/ui/CrmSearch/CrmSearch";
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import Button from 'components/ui/Button/Button';
import JokerSelect from 'components/ui/JokerSelect';
import DatePickerRange from 'components/ui/DatePickerRange';

const cx = classNames.bind(styles);

const FeedFilter = ({ onSubmit, reloadFilters, actionTypesList, userId, queryMessage}) => {
  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      query: '',
      action_type: { value: 'any', label: 'Any' },
      from: '',
      to: '',
      created_at: '',
    };
  };
  const { handleSubmit, reset, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const submit = (data) => {
    const postData = {
      action_type: getValue(data['action_type']) && getValue(data['action_type']),
      from: new Date(data.created_at?.from).getTime() / 1000,
      to: new Date(data.created_at?.to).getTime() / 1000,
      query: data?.query,
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={cx('filters')}
    >
      <Row>
        <Col md={12}>
          <ComplexSearchBar searchOnly={true} className="mt-0" message={reloadFilters ? '' : queryMessage}>
            <CrmSearch
              id='query'
              name='query'
              control={control}
              placeholder='Search by Operator UUID'
              onSearch={() => submit(getValues())}
              handleChange={(value: string) => {
                queryMessage && setQueryMessage('');
                if (value?.length === 0) submit(getValues());
              }}
            />
          </ComplexSearchBar>
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={4} md={6}>
          <JokerSelect
            label={'Action types'}
            control={control}
            id='action_type'
            name='action_type'
            options={actionTypesList}
          />
        </Col>
        <Col xl={3} lg={4} md={6}>
          <DatePickerRange
            name='created_at'
            control={control}
            label={'Action date range'}
            placeholder='From - To'
          />
        </Col>
        <Col>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color='var(--white)' size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText='Reset'
              type='reset'
              onClick={() => resetForm()}
              disabled={!getDirtyFieldsLength()}
            />
            <Button
              buttonText='Apply'
              type='submit'
              onClick={handleSubmit(submit)}
              disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default FeedFilter;
