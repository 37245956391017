import classNames       from 'classnames/bind';
import Button           from 'components/ui/Button/Button';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';

import { ArrowsClockwise }          from 'phosphor-react';
import React, { useEffect, useRef } from 'react';
import { Col, Row }                 from 'react-bootstrap';
import { useForm }                  from 'react-hook-form';
import 'react-toggle/style.css';
import { ComplexSearchBar } from "../../../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect, defaultQueryCategoryOption
} from "../../../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../../../utils/useGetSearchCategoriesOptions";
import styles                       from './filter.module.scss';
import './reload-button.scss';
import { isFormChange } from '../../../../../../utils/isFormChange';

const cx = classNames.bind(styles);

const TradingAccFilter = ({ onSubmit, reloadFilters, taStatusesOpt, taTypesOpt, queryMessage}) => {
  const componentMounted = useRef(true);
  const tradingAccountSearchOptions = useSearchCategoriesOptions('tradingAccountByClient');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      type: [],
      status: [],
    };
  };
  const { handleSubmit, reset, getValues, watch, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, tradingAccountSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        type: getValue(data['type']),
        status: getValue(data['status']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit(null);
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={cx('filters')}
    >
      <Row>
        <Col md={12}>
          <ComplexSearchBar className="mt-0" message={reloadFilters ? '' : queryMessage}>
            <>
              <CrmSearchCategorySelect
                id='query_category'
                name='query_category'
                control={control}
                options={tradingAccountSearchOptions}
                onSelectChange={() => {
                  const { query } = getValues();
                  if (query) submit(getValues());
                }}
              />
              <CrmSearch
                id='query'
                name='query'
                control={control}
                placeholder={dynamicSearchPlaceholder}
                onSearch={() => submit(getValues())}
                handleChange={(value: string) => {
                  if (value?.length === 0) submit(getValues());
                }}
              />
            </>
          </ComplexSearchBar>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={6} xl={3}>
          <JokerMultiSelect
            label='Status'
            control={control}
            id='status'
            name='status'
            options={taStatusesOpt}
            hideSearch={true}
          />
        </Col>

        <Col xs={12} md={12} lg={12} xl={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color='var(--white)' size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText='Reset'
              type='reset'
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText='Apply'
              type='submit'
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default TradingAccFilter;
