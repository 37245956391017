import { createPortal } from "react-dom";
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from '../client-filter.module.scss';
import { ArrowsClockwise, Info } from 'phosphor-react';
import Search from '../../../../../components/ui/Search';
import JokerSelect from '../../../../../components/ui/JokerSelect';
import PaginateSelect from '../../../../../components/ui/PaginateSelect';
import JokerMultiSelect from '../../../../../components/ui/JokerMultiSelect';
import SaveFilterPopup from '../../SearchClientsPopups/SaveFilterPopup';
import ButtonReset from '../../../../../components/ui/Button/ButtonReset';
import Button from '../../../../../components/ui/Button';
import DeleteFilterPopup from '../../SearchClientsPopups/DeleteFilterPopup/DeleteFilterPopup';
import { useEffect } from 'react';
import FormInput from '../../../../../components/ui/FormInput';
import { Control, UseFormGetValues, UseFormHandleSubmit } from 'react-hook-form';
import { CountryOpt, CustomFilter } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';
import { clearEmptyFilter } from '../../../../../utils/clearEmptyFilter.ts';
import {
  defaultDateTimeFormat,
  ModernDateRangeInput,
  ModernDateTimeInputHeader
} from "../../../../../components/ui/ModernDateRangeInput";

const cx = classNames.bind(styles);

type Props = {
  selectedDesks: ListValue[];
  setSelectedDesks: (value: ListValue[]) => void;
  selectedTeams: ListValue[];
  setSelectedTeams: (value: ListValue[]) => void;
  desks: ListValue[];
  teams: ListValue[];
  operators: ListValue[];

  control: Control<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  getValues: UseFormGetValues<any>;

  submit: (data?: any) => void;
  setSelectedDynamicFilter: (val: any[]) => void;
  fetchFilterSet: () => void;
  fetchClientDynamicStatuses: () => void;
  resetForm: () => void;
  clearValues: () => void;
  filterSet: any[];
  customFilterList: CustomFilter[];

  fields: any[];

  isMobile: () => boolean;
  isNotEmptyFilterOption: (data: any) => boolean;
  isEmpty: (data: any) => boolean;

  oldFilters: boolean;

  assignStatusList: ListValue[];
  reloadFilters: boolean;
};

const SearchSection = (props: Props) => {
  const {
    selectedDesks,
    setSelectedDesks,
    selectedTeams,
    setSelectedTeams,
    desks,
    teams,
    operators,
    fields,
    control,
    handleSubmit,
    submit,
    getValues,
    isMobile,
    isNotEmptyFilterOption,
    isEmpty,
    oldFilters,
    filterSet,
    customFilterList,
    assignStatusList,
    fetchFilterSet,
    fetchClientDynamicStatuses,
    resetForm,
    clearValues,
    setSelectedDynamicFilter,
    reloadFilters,
  } = props;

  return (
    <>
      <Row className={cx(fields?.length ? 'range-wrapper' : '')}>
        {fields?.map((item, index) => {
          if (item.type === 'input') {
            return (
              <Col xl={4} lg={6} md={6} key={index}>
                <Search
                  label="Search by"
                  id="query"
                  control={control}
                  name="query"
                  placeholder="Name, E-mail, Phone, ID, Trading account"
                  onSearch={handleSubmit(submit)}
                  handleChange={(e) => {
                    if (e.length === 0) submit(getValues());
                  }}
                  tooltip={{
                    show: true,
                    tooltipText: 'Name, E-mail, Phone, ID, Trading account',
                    dataEvent: 'click',
                    dataFor: 'query',
                    delayHide: 200,
                    icon: <Info size={18} />,
                  }}
                />
              </Col>
            );
          }
          if (item.type === 'range') {
            return (
              <Col xl={2} lg={4} md={4} key={index}>
                <Row className={cx('range-wrapper')}>
                  <Col md={6}>
                    <FormInput
                      labelStyle={{ width: isMobile() ? '200%' : '300%' }}
                      name={`${item.value}.from`}
                      type="number"
                      placeholder="0.00"
                      min={0}
                      label={item.label}
                      control={control}
                    />

                    <span className={cx('range-divider')}>-</span>
                  </Col>
                  <Col md={6}>
                    <FormInput
                      name={`${item.value}.to`}
                      min={0}
                      type="number"
                      label="&nbsp;"
                      placeholder="0.00"
                      control={control}
                    />
                  </Col>
                </Row>
              </Col>
            );
          }
          if (item.type === 'date') {
            return (
              <Col xl={6} lg={6} md={6} key={index}>
                {createPortal(
                  <div id={`custom-${item.value}`} className='date-input' style={{position: 'relative', zIndex: 99}}/>,
                  document.body
                )}
                <ModernDateRangeInput
                  name={item.value}
                  control={control}
                  label={item.label}
                  placeholder='From - To'
                  portalId={`custom-${item.value}`}
                  dateFormat={defaultDateTimeFormat}
                  allowToChangeViaInput={true}
                  renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                    <ModernDateTimeInputHeader
                      {...props}
                      from={props.from}
                      to={props.to}
                      onTimeChangeHandler={onTimeChangeHandler}
                      showRange
                    />
                  )}
                />
              </Col>
            );
          }
          if (item.type === 'select_one') {
            return (
              <Col xl={2} lg={6} md={6} key={index}>
                <JokerSelect
                  label={item.label}
                  control={control}
                  id={item.value}
                  name={item.value}
                  options={item.value === 'assign_status' ? assignStatusList : item.options}
                />
              </Col>
            );
          }
          if (item.type === 'paginate_select') {
            return (
              <Col xl={2} lg={6} md={6} key={index}>
                <PaginateSelect
                  isSearchable={true}
                  isMulti={true}
                  placeholder={'Any'}
                  label={item.label}
                  control={control}
                  name={item.value}
                  onChange={(page, search) =>
                    customFilterList
                      .find((filter) => filter.label === item.label)
                      ?.getOptions(page, search)
                  }
                />
              </Col>
            );
          }

          if (item.type === 'descsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id}>
                <JokerMultiSelect
                  label="Desks"
                  control={control}
                  id="desks"
                  name="deskIds"
                  options={desks}
                  ungroup
                  onChange={(value) => {
                    setSelectedDesks(value);
                  }}
                />
              </Col>
            );
          }
          if (item.type === 'teamsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id}>
                <JokerMultiSelect
                  label="Teams"
                  control={control}
                  id="teams"
                  name="teamIds"
                  options={teams}
                  ungroup
                  onChange={(value) => {
                    setSelectedTeams(value);
                  }}
                />
              </Col>
            );
          }
          if (item.type === 'operatorsSelect') {
            return (
              <Col xl={4} lg={6} md={6} key={item.id}>
                <JokerMultiSelect
                  label="Operators"
                  control={control}
                  id="operators"
                  name="operatorIds"
                  options={operators}
                  ungroup
                />
              </Col>
            );
          }

          return (
            <Col xl={2} lg={4} md={4} key={item.id}>
              <JokerMultiSelect
                label={item.label}
                control={control}
                id={item.value}
                name={`dynamic_statuses.${index}.${item.value}`}
                options={item.options}
                onChange={item.onchange}
                ungroup={true}
              />
            </Col>
          );
        })}
      </Row>
      <Row style={{ paddingBottom: 34 }}>
        {/*{fields?.length && isNotEmptyFilterOption(getValues()) ?*/}
        {/*TODO Если нужно вернуть проверку на пустые фильтра*/}
        {fields?.length ? (
          <>
            <SaveFilterPopup
              filterSet={filterSet}
              triggerBtn={
                <ButtonReset
                  buttonText={!isEmpty(getValues('custom_filters')) ? 'Update' : 'Save'}
                  // buttonType='outline'
                  style={{ maxWidth: 70, justifyContent: 'center' }}
                  onClick={null}
                />
              }
              prepareFiltersData={() => {
                return { ...clearEmptyFilter(getValues()), oldFilters: oldFilters };
              }}
              isUpdate={!isEmpty(getValues('custom_filters'))}
              onCloseModal={() => {
                fetchFilterSet();
                fetchClientDynamicStatuses();
              }}
              type="client"
            />
            <Button
              buttonText="Apply filters"
              type="submit"
              buttonType="outline"
              style={{ maxWidth: 120, justifyContent: 'center' }}
              onClick={handleSubmit(submit)}
            />
            <Button
              buttonText="Reset filters"
              type="button"
              buttonType="outline"
              style={{ maxWidth: 120, justifyContent: 'center' }}
              onClick={() => clearValues()}
            />
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={handleSubmit(submit)}
            />
          </>
        ) : null}

        {!isEmpty(getValues('custom_filters')) ? (
          <DeleteFilterPopup
            triggerBtn={
              <ButtonReset
                buttonText="Delete"
                buttonType="button"
                style={{ maxWidth: 70, justifyContent: 'center' }}
                onClick={null}
              />
            }
            prepareFiltersData={() => {
              return { ...clearEmptyFilter(getValues()), oldFilters: oldFilters };
            }}
            onCloseModal={() => {
              fetchFilterSet();
              fetchClientDynamicStatuses();
              resetForm();
              setSelectedDynamicFilter([]);
            }}
          />
        ) : null}
      </Row>
    </>
  );
};

export default SearchSection;
