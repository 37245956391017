import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'
import moment from 'moment'
import ShowLastNote from '../../../../../pages/Clients/SearchClients/SearchClientsPopups/LastNotePopup'
import { get45SymbolsLastNote } from '../../../../../helpers/get45SymbolsLastNote'
import { Note, ShortNote } from "../../../../../models/Clients/ClientNote.ts";
import { UserProfile } from "../../../../../models/User.ts";

const cx = classNames.bind(styles)

interface ILastNoteProps {
  note_last: ShortNote;
  user_profile: UserProfile;
}
const LastNote: FC<ILastNoteProps> = ({ note_last, user_profile }) => {
  return (
    <div className={cx('client__lastnote')}>
      {note_last?.create_at ? (
        <>
          <span>{moment.unix(note_last?.create_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(note_last?.create_at).format('HH:mm:ss')}</span>
          <span>
                {`${note_last?.operator_name || ''}`}
              </span>
          <ShowLastNote
            note_last={note_last}
            user={user_profile}
            triggerBtn={
              <span className={cx('client__lastnote-btn')}>
                      {get45SymbolsLastNote(note_last)}
                    </span>
            }
          />
        </>
      ) : (
        <span className={cx('client__lastnote-empty')}>NONE</span>
      )}
    </div>
  )
}

export default memo(LastNote)
