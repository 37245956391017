import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './clientsPopups.mudule.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import SelectComponent from 'components/ui/Select/Select';
import { clientDynamicStatusesByKey, changeAcquisitionStatus } from 'api/clients';
import PaginateSelect from '../../../../components/ui/PaginateSelect';
import {
  fetchDesksForType,
  fetchTeamsForDesk,
} from '../../../../utils/managment/fetchData';
import { USER_SELECT_FILTER_DEFAULT_VALUE } from '../../../../constants/clients/filters.const';

const cx = classNames.bind(styles);

const ChangeAcquisitionStatus = ({ triggerBtn, updateComponent, userId, actionType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicFilter, setDynamicFilter] = useState<any>([]);

  const componentMounted = useRef(true);
  const setDefaultValues = () => {
    return {
      desk_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      team_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      status: null,
      sales_status: null,
    };
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatusesByKey(actionType).then((res) => {
      if (componentMounted.current) {
        const filter = res.data.data;
        setDynamicFilter({
          value: filter.key,
          label: filter.translate,
          options: filter.activeStatuses.map((item) => ({
            value: item.id,
            label: item.translate,
          })),
        });
      }
    });
  };

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const clearEmptyField = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    reset();
    closeModal();
  };
  const onSubmit = (close, data) => {
    setIsLoading(true);

    const sendData = {
      bulk_type: actionType,
      statusId: data && data.sales_status ? data.sales_status.value : null,
      user_ids: [userId],
    };

    changeAcquisitionStatus(clearEmptyField(sendData))
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          setTimeout(updateComponent, 3000);
          setTimeout(() => notify({
            type: 'success',
            message: `Client sales ${actionType} successfull`,
            timeOut: 3000,
          }), 3000)
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOpenPopup = () => {
    fetchClientDynamicStatuses();
  };
  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const setDefaultValuesTeam = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: '',
      statusId: getValues('status'),
      user_ids: [userId],
    };
  };
  const setDefaultValuesOperator = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: getValues('team_id'),
      statusId: getValues('status'),
      user_ids: [userId],
    };
  };
  const updateOperatorWithTeam = (team) => {
    reset(setDefaultValuesOperator());
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpenPopup}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`Client ${actionType} update`}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          <SelectComponent
                            name='sales_status'
                            label={`${actionType} Status`}
                            control={control}
                            options={dynamicFilter.options}
                            rules={{ required: ' Field is required' }}
                            errors={errors.sales_status}
                            isSearchable={false}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText='Cancel'
                          buttonType='outline'
                          size='big'
                          type='button'
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType='primary'
                          size='big'
                          showSpinner={isLoading}
                          buttonText={'Apply change'}
                          type='submit'
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ChangeAcquisitionStatus;
