import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import styles from './checkbox.module.scss';

const cx = classNames.bind(styles);

const CheckBox = (props) => {
  const { zIndex } = props;
  const { field } = useController(props);
  const { label } = props;
  const [checked, setCheck] = useState<boolean>(field.value);

  useEffect((): void => {
    field.onChange(checked);
  }, [checked]);

  return (
    <>
      <div  className={cx('input-wrap')}>
        <input
          style={{zIndex}}
          {...field}
          id={props.id}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          defaultChecked={props.checked}
        />
        <div className={cx('label-wrap', props.labelNoClickable ? 'no-click' : '')}>
          {!props.labelNoClickable && (
            <label
              htmlFor={props.id}
              className={props.errors ? cx('input__label', 'error') : cx('input__label')}
            >
              {label}
              {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
            </label>
          )}
          {props.labelNoClickable && (
            <>
              <i
                className={cx(
                  'checkbox-icon',
                  checked ? 'icon-checked' : '',
                  props.errors ? 'error' : '',
                )}
                onClick={(): void => {
                  setCheck(!checked);
                }}
              ></i>
              <div
                className={
                  props.errors ? cx('input__label-no-click', 'error') : cx('input__label-no-click')
                }
              >
                {label}
                {props.rules && props.rules.required ? (
                  <span className={cx('required')}>*</span>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckBox;
